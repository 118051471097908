import React, {createContext} from "react";
import useConnectWallet from "../hooks/useConnectWallet";

const ConnectWalletContext = createContext({
    address: "",
    cookie: "",
    client: undefined,
    wallet: undefined,
    login: () => {
        return false;
    },
    logout: () => {
        return false;
    }
})

export function WalletProvider({children}) {
    let {address, cookie, client, login, logout,wallet} = useConnectWallet()

    return (
        <ConnectWalletContext.Provider value={{address, cookie, client, login, logout,wallet}}>
            {children}
        </ConnectWalletContext.Provider>
    )
}

export function useWallet() {
    return React.useContext(ConnectWalletContext)
}