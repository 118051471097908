import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styles from "./Sidebar.module.scss";
import logo from "../assets/logo.svg";
import SearchDrawer from "./SearchDrawer";
import NotiDrawer from "./NotiDrawer";
import Button from "app/components/button/button";
import { useWallet } from "app/Loop_Reuseable_Component/packages/loop_wallet";
import { useHook } from "app/hooks/common";
import LoginModal from "app/components/loginModal/LoginModal";
import { Trans } from "react-i18next";
import LanguageSelector from "app/util/LanguageSelector";
import Header from "header/Header";
import { isCreator } from "app/core/common";
import { FaLessThanEqual } from "react-icons/fa";
import { getParam } from "app/helpers/helper";
import MobileMenu from "./MobileMenu";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function Sidebar({
  isLoggedIn,
  children,
  containerRef,
}: {
  isLoggedIn: boolean;
  children: any;
  containerRef: any;
}) {
  const { logout } = useWallet();
  const { userInfo } = useHook();
  const location = useLocation();
  const [notiDrawer, setNotiDrawer] = useState(false);
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const [searchDrawer, setSearchDrawer] = useState(false);
  const [noheader, setNoHeader] = useState(false);
  const [showNFTDropdown, setShowNFTDropdown] = useState(false); // State for showing NFT Collections dropdown
  const [showNFTDropdownAi, setShowNFTDropdownAi] = useState(false);
  const history = useHistory();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const origin = window.location.origin;
  const pathname = window.location.pathname;

  const userNav = [
    /* {
      name: "Wallet",
      to: `/user/${userInfo?.username ?? userInfo?.id}?tab=nfts`,
      icon: origin + "/assets/icons/wallet",
      auth: true,
      type: "page",
      tab: ["nfts"],
    }, */
    // {
    //   name: <Trans>profile</Trans>,
    //   to: `/user/${userInfo?.username ?? userInfo?.id}`,
    //   icon: origin + "/assets/icons/profile",
    //   auth: true,
    //   type: "page",
    //   tab: ["collections", "posts"],
    // },
    // {
    //   name: "Wallet",
    //   to: `/user/${userInfo?.username ?? userInfo?.id}?tab=nfts`,
    //   icon: origin + "/assets/icons/wallet",
    //   auth: true,
    //   type: "page",
    //   tab: ["nfts"],
    // },
  ];

  // Mobile Menus
  const mobileMenu = [
    {
      name: "Feed",
      to: "/collections",
      icon: origin + "/assets/icons/home",
      auth: true,
      type: "page",
    },
    // {
    //   name: "Artist Loops",
    //   to: "/aitools",
    //   icon: origin + "/assets/icons/loops",
    //   auth: false,
    //   type: "page",
    // },
    // {
    //   name: "W3 Awards",
    //   to: "/wma/list",
    //   icon: origin + "/assets/icons/wma",
    //   auth: false,
    //   type: "page",
    // },
    // {
    //   name: <Trans>profile</Trans>,
    //   to: `/user/${userInfo?.username ?? userInfo?.id}`,
    //   icon: origin + "/assets/icons/profile",
    //   auth: true,
    //   type: "page",
    //   tab: ["collections", "posts"],
    // },
    {
      name: "",
      to: "#",
      icon: origin + "/assets/icons/menu_icon",
      auth: true,
      type: "mobile_menu",
    },
  ];

  // Desktop Menus
  const mainNav = [
    {
      name: "Collectibles",
      to: "/collections",
      icon: origin + "/assets/icons/home",
      auth: false,
      type: "dropdownCollectibles",
      submenu: [
        {
          name: "Dashboard",
          to: "/",
          auth: true,
          type: "page",
        },
        {
          name: "In Draft",
          to: "/inDraft/",
          auth: true,
          type: "page",
        },

        {
          name: "Live Collections",
          to: "/liveCollections/",
          auth: true,
          type: "page",
        },
        // Add more submenu items as needed
      ],
    },
    // {
    //   name: "AI Tools",
    //   to: "/aitools",
    //   icon: origin + "/assets/icons/home",
    //   auth: false,
    //   type: "dropdownAi",
    //   submenu: [
    //     {
    //       name: "Mastering",
    //       to: "/mastering",
    //       auth: true,
    //       type: "page",
    //     },
    //     {
    //       name: "AI Music Video",
    //       auth: true,
    //       type: "page",
    //     },

    //     // Add more submenu items as needed
    //   ],
    // },
    // {
    //   name: "Mastering",
    //   to: "/mastering",
    //   icon: origin + "/assets/icons/mastering",
    //   auth: true,
    //   type: "page",
    // },
  ];

  const handleSubItems = (to) => {
    history.push(to);
    if (to === "/" && showNFTDropdown) {
      setShowNFTDropdown(false);
    }

    if (to === "/mastering" && showNFTDropdown) {
      setShowNFTDropdown(false);
    }
  };

  useEffect(() => {
    if (
      location?.pathname === "/" ||
      location?.pathname === "/inDraft/" ||
      location?.pathname === "/liveCollections/"
    ) {
      setShowNFTDropdown(true);
    } else {
      setShowNFTDropdown(false);
    }
  }, [location]);

  useEffect(() => {
    if (location?.pathname === "/mastering") {
      setShowNFTDropdownAi(true);
    } else {
      setShowNFTDropdownAi(false);
    }
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRedirectToCollectionTypes = () => {
    history.push(`/collections`);
  };

  const handleRedirectToAiTools = () => {
    history.push(`/aitools`);
  };

  return (
    <>
      <Header noheader={noheader} />
      <div
        className={`${styles.sidebar} container`}
        style={notiDrawer || searchDrawer ? { overflow: "hidden" } : {}}
      >
        {!pathname?.includes("/login") && (
          <div
            className={
              notiDrawer || searchDrawer
                ? `${styles.sidebarCollapse} ${styles.sidebarContent}`
                : styles.sidebarContent
            }
          >
            {/* Search */}
            <SearchDrawer show={searchDrawer} setShow={setSearchDrawer} />

            {/* Notifications */}
            <NotiDrawer show={notiDrawer} setShow={setNotiDrawer} />

            {/* Mobile Menu */}
            <MobileMenu show={mobileDrawer} setShow={setMobileDrawer} />

            <div className={userInfo ? styles.navbar : `${styles.navbar}`}>
              <ul className={styles.mobileMenu}>
                {!!userInfo && (
                  <>
                    {mobileMenu?.map((item: any, index: Number) => {
                      return (
                        <li
                          className={
                            location?.pathname === item.to ? styles.active : ""
                          }
                          key={`item-${index}`}
                          hidden={
                            item?.creatorOnly
                              ? !isCreator(userInfo?.role)
                              : item.auth
                              ? !Boolean(userInfo)
                              : false
                          }
                        >
                          <Link
                            to={item?.to}
                            onClick={() => {
                              if (item.type === "search") {
                                notiDrawer && setNotiDrawer(false);
                                mobileDrawer && setMobileDrawer(false);
                                setSearchDrawer(!searchDrawer);
                              } else if (item.type === "notifications") {
                                searchDrawer && setSearchDrawer(false);
                                mobileDrawer && setMobileDrawer(false);
                                setNotiDrawer(!notiDrawer);
                              } else if (item.type === "mobile_menu") {
                                setMobileDrawer(!mobileDrawer);
                              } else {
                                setSearchDrawer(false);
                                setNotiDrawer(false);
                                setMobileDrawer(false);
                              }
                            }}
                          >
                            <img
                              alt={item.name}
                              src={
                                location?.pathname === item.to
                                  ? `${item.icon}_active.svg`
                                  : `${item.icon}.svg`
                              }
                            />
                            <span>{item?.name}</span>
                          </Link>
                        </li>
                      );
                    })}
                    <hr />
                  </>
                )}
              </ul>
              <ul className={styles.desktopMenu}>
                {/* Main Navbar */}
                {mainNav?.map((item: any, index: Number) => {
                  return (
                    <>
                      <li
                        // add here onclick event to go to collections when screen its tablet
                        className={
                          (item.type === "dropdownCollectibles" &&
                            showNFTDropdown) ||
                          (item.type === "dropdownAi" && showNFTDropdownAi)
                            ? styles.active
                            : location?.pathname === item?.to
                            ? styles.active
                            : ""
                        }
                        key={`item-${index}`}
                        hidden={
                          item.creatorOnly
                            ? !isCreator(userInfo?.role)
                            : item.auth
                            ? !Boolean(userInfo)
                            : false
                        }
                      >
                        <div
                          className={styles.studio_options}
                          onClick={() => {
                            if (item.type === "search") {
                              notiDrawer && setNotiDrawer(false);
                              setSearchDrawer(!searchDrawer);
                            } else if (item.type === "notifications") {
                              searchDrawer && setSearchDrawer(false);
                              setNotiDrawer(!notiDrawer);
                            } else if (
                              item.type === "dropdownCollectibles" &&
                              screenWidth > 1263
                            ) {
                              setShowNFTDropdown((prev) => !prev);
                            } else if (
                              item.type === "dropdownCollectibles" &&
                              screenWidth <= 1263
                            ) {
                              handleRedirectToCollectionTypes();
                            } else if (
                              item.type === "dropdownAi" &&
                              screenWidth > 1263
                            ) {
                              setShowNFTDropdownAi((prev) => !prev);
                            } else if (
                              item.type === "dropdownAi" &&
                              screenWidth <= 1263
                            ) {
                              handleRedirectToAiTools();
                            } else if (item.type === "page") {
                              handleSubItems(item.to);
                            } else {
                              setSearchDrawer(false);
                              setNotiDrawer(false);
                            }
                          }}
                        >
                          <img
                            alt={item.name}
                            src={
                              item.type === "dropdownCollectibles" &&
                              showNFTDropdown
                                ? `${item?.icon}_active.svg`
                                : location?.pathname === item?.to
                                ? `${item?.icon}_active.svg`
                                : item.type === "dropdownAi" &&
                                  showNFTDropdownAi
                                ? `${item?.icon}_active.svg`
                                : location?.pathname === item?.to
                                ? `${item?.icon}_active.svg`
                                : `${item?.icon}.svg`
                            }
                          />
                          <span>{item?.name}</span>
                          {(item?.type === "dropdownCollectibles" &&
                            !showNFTDropdown) ||
                          (item?.type === "dropdownAi" &&
                            !showNFTDropdownAi) ? (
                            <div className={styles.studio_icon}>
                              <ArrowRightIcon />
                            </div>
                          ) : (item?.type === "dropdownCollectibles" &&
                              showNFTDropdown) ||
                            (item?.type === "dropdownAi" &&
                              showNFTDropdownAi) ? (
                            <div className={styles.studio_icon}>
                              <ArrowDropDownIcon />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </li>
                      <div>
                        {item?.type === "dropdownCollectibles" &&
                          showNFTDropdown && (
                            <div
                              className={`${styles.dropdownContent} ${
                                showNFTDropdown ? "showDropdown" : ""
                              }`}
                            >
                              {item?.submenu?.map(
                                (subItem: any, subIndex: Number) => (
                                  <div
                                    key={`subItem-${subIndex}`}
                                    className={
                                      location.pathname === subItem.to
                                        ? styles.active_suboption
                                        : styles.normal_suboption
                                    }
                                    onClick={() => handleSubItems(subItem.to)}
                                  >
                                    <span>{subItem?.name}</span>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        {item?.type === "dropdownAi" && showNFTDropdownAi && (
                          <div
                            className={`${styles.dropdownContent} ${
                              showNFTDropdownAi ? "showDropdown" : ""
                            }`}
                          >
                            {item?.submenu?.map(
                              (subItem: any, subIndex: Number) => (
                                <div
                                  key={`subItem-${subIndex}`}
                                  className={
                                    location.pathname === subItem.to
                                      ? styles.active_suboption
                                      : styles.normal_suboption
                                  }
                                  onClick={() => handleSubItems(subItem.to)}
                                >
                                  <span
                                    style={{ color: !subItem.to ? "gray" : "" }}
                                  >
                                    {subItem?.name}
                                  </span>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}
                {/* <div className={styles.sideCredits}>
                  <p>Credits: 0</p>
                  <Link to="/price">
                    <button>Buy Credits</button>
                  </Link>
                </div> */}

                {/* User Navbar */}
                {/* {!!userInfo && (
                  <>
                    {userNav.map((item: any, index: Number) => {
                      return (
                        <li
                          className={
                            !!item?.tab?.includes(getParam("tab"))
                              ? styles.active
                              : ""
                          }
                          key={`item-${index}`}
                          hidden={item.auth ? !Boolean(userInfo) : false}
                        >
                          <Link
                            to={item.to}
                            onClick={() => {
                              if (item.type === "search") {
                                notiDrawer && setNotiDrawer(false);
                                setSearchDrawer(!searchDrawer);
                              } else if (item.type === "notifications") {
                                searchDrawer && setSearchDrawer(false);
                                setNotiDrawer(!notiDrawer);
                              } else {
                                setSearchDrawer(false);
                                setNotiDrawer(false);
                              }
                            }}
                          >
                            <img
                              alt={item.name}
                              src={
                                !!item?.tab?.includes(getParam("tab"))
                                  ? `${item.icon}_active.svg`
                                  : `${item.icon}.svg`
                              }
                            />
                            <span>{item.name}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </>
                )} */}

                {/* {rewardsNav.map((item: any, index: Number) => {
                  return (
                    <li
                      className={
                        location.pathname === item.to ? styles.active : ""
                      }
                      key={`item-${index}`}
                      hidden={
                        item.creatorOnly
                          ? !isCreator(userInfo?.role)
                          : item.auth
                          ? !Boolean(userInfo)
                          : false
                      }
                    >
                      <Link
                        to={item.to}
                        onClick={() => {
                          if (item.type === "search") {
                            notiDrawer && setNotiDrawer(false);
                            setSearchDrawer(!searchDrawer);
                          } else if (item.type === "notifications") {
                            searchDrawer && setSearchDrawer(false);
                            setNotiDrawer(!notiDrawer);
                          } else {
                            setSearchDrawer(false);
                            setNotiDrawer(false);
                          }
                        }}
                      >
                        <img
                          alt={item.name}
                          src={
                            location.pathname === item.to
                              ? `${item.icon}_active.svg`
                              : `${item.icon}.svg`
                          }
                        />
                        <span>{item.name}</span>
                      </Link>
                    </li>
                  );
                })} */}
              </ul>

              <div className={styles.footer}>
                {/* <LanguageSelector /> */}
                {/* {isLoggedIn ? (
                  <Button
                    outline={false}
                    onClick={() => {
                      logout();
                      window.location.reload();
                    }}
                  >
                    <img alt="logout" src={`/assets/icons/logout.svg`} />
                    <span>Logout</span>
                  </Button>
                ) : (
                  <Button onClick={() => setShowLoginModal(true)}>Login</Button>
                )} */}
                {/* {!isLoggedIn && <Button onClick={() => setShowLoginModal(true)}>Login</Button>} */}
              </div>
            </div>
          </div>
        )}
        <div className={styles.bodyContent} id="bodyContent" ref={containerRef}>
          {children}
        </div>
      </div>
    </>
  );
}
