import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Header.module.scss";
//import logo from "../assets/logo.svg";
import logo from "../assets/fansLogo.png";
import caret from "../assets/caret.svg";
import Button from "app/components/button/button";
import { useWallet } from "app/Loop_Reuseable_Component/packages/loop_wallet";
import { DefaultAvatar } from "app/core/common";
import { useHook } from "app/hooks/common";
import { Trans } from "react-i18next";
import { DirectusImage } from "../app/core/common";
import { truncate } from "app/helpers/helper";
import { useLogin } from "app/context/loginProvider";
import EmailIcon from "@mui/icons-material/Email";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";

type HeaderProps = {
  noheader: boolean;
};

export default function Header({ noheader }: HeaderProps) {
  const { logout } = useWallet();
  const { userInfo } = useHook();
  const location = useLocation();
  const [notiDrawer, setNotiDrawer] = useState(false);
  const [searchDrawer, setSearchDrawer] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const { showSignup, toggleLoginModal, toggleSignupModal } = useLogin();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const origin = window.location.origin;
  const pathname = window.location.pathname;
  const inbox = {
    name: "Inbox",
    to: `/inbox/?tab=inbox`,
    icon: origin + "/assets/icons/inbox",
    auth: true,
    type: "page",
    tab: ["inbox"],
  };

  {
    /* add more actions here... */
  }
  const actions = [
    {
      name: <Trans>profile</Trans>,
      to: `https://loop.fans/user/${userInfo?.username}/`,
    },
  ];

  return (
    <>
      <div className={styles.header}>
        <div className="container">
          <div className={styles.items}>
            <div className={styles.left}>
              <div className={styles.logo_section}>
                <div className={styles.logo}>
                  <Link to="/">
                    <img
                      src="https://loop-markets.directus.app/assets/27e236b8-0989-42a1-90b8-774f33839f54"
                      className={styles.logo}
                      alt="logo"
                    />
                    {/* <span>FANS</span> */}
                  </Link>
                </div>
              </div>
              {noheader ? null : (
                <div>
                  <ul className={styles.nav}>
                    <li>
                      <a href="https://loop.fans">
                        <Trans>Home</Trans>
                      </a>
                    </li>

                    {/* <li>
                      <a href="https://create.loop.fans/create" target="_blank">
                        AI Music Tools
                      </a>
                    </li> */}
                    {/* <li><a href="https://www.loop.fans/clubs">VIP Clubs</a></li> */}
                  </ul>
                </div>
              )}
            </div>

            <div className={styles.right}>
              {/* {noheader ? null : (
                <div className={styles.actions}>
                  <LanguageSelector />
                </div>
              )} */}
              {noheader ? null : (
                <>
                  {userInfo ? (
                    <div
                      className={styles.profile}
                      style={
                        dropdownOpen ? { borderRadius: "25px 25px 0 0" } : {}
                      }
                    >
                      <a
                        href="https://loop.fans/inbox/?tab=inbox"
                        style={{ color: "lightgray" }}
                      >
                        <div
                          className={styles.icon}
                          /* onMouseEnter={() => setShowTooltip(true)}
                          onMouseLeave={() => setShowTooltip(false)} */
                        >
                          <EmailIcon fontSize="small" />

                          {showTooltip && (
                            <div className={styles.tooltip}>
                              <span>Inbox</span>
                            </div>
                          )}
                        </div>
                      </a>

                      <a
                        href={`https://loop.fans/user/${
                          userInfo?.username ?? userInfo?.id
                        }?tab=collections`}
                        style={{ color: "lightgray" }}
                      >
                        <div
                          className={styles.icon}
                          /* onMouseEnter={() => setShowTooltip(true)}
                          onMouseLeave={() => setShowTooltip(false)} */
                        >
                          <WalletIcon fontSize="small" />

                          {showTooltip && (
                            <div className={styles.tooltip}>
                              <span>Inbox</span>
                            </div>
                          )}
                        </div>
                      </a>

                      <img
                        src={
                          userInfo?.avatar
                            ? DirectusImage(userInfo?.avatar)
                            : DirectusImage(DefaultAvatar)
                        }
                        alt="loop-user-picture"
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                      />

                      {dropdownOpen && (
                        <div className={styles.dropdown_content}>
                          {actions.map((action) => {
                            return (
                              <>
                                <a
                                  href={action.to}
                                  onClick={() => setDropdownOpen(false)}
                                >
                                  {action.name}
                                </a>
                              </>
                            );
                          })}
                          <Link
                            to="#"
                            onClick={() => {
                              logout();
                              //window.Bugpilot.logout();
                              window.location.reload();
                            }}
                          >
                            Logout
                          </Link>
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      {noheader ? null : (
                        <>
                          <Button
                            className={styles.loginButton}
                            onClick={() => {
                              toggleLoginModal();
                              toggleSignupModal(false);
                            }}
                          >
                            Login
                          </Button>
                          <Button
                            className={styles.signupButton}
                            onClick={() => {
                              toggleLoginModal();
                              toggleSignupModal(true);
                            }}
                          >
                            Join Now
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
