import React, { useState, useRef, useEffect } from 'react';

const AudioDuration = ({ audioUrl }) => {
  const [duration, setDuration] = useState(null);
  const audioRef = useRef(null);

  useEffect(() => {
    const handleLoadedMetadata = () => {
      setDuration(audioRef.current.duration);
    };

    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
    };
  }, [audioUrl]);

  return (
    <div>
      <audio ref={audioRef} src={audioUrl} preload="metadata" />
      {duration ? (
        <p>{Math.floor(duration / 60)}:{Math.floor(duration % 60).toString().padStart(2, '0')}</p>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default AudioDuration;
