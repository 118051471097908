import Button from "app/components/button/button";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import {
  LoginWithFB,
  LoginWithGoogle,
  useWallet,
} from "app/Loop_Reuseable_Component/packages/loop_wallet";
import { REACT_APP_SOCKET_URL } from "app/Loop_Reuseable_Component/packages/loop_wallet/constants";
import styles from "./Login.module.scss";

interface LocationState {
  from: {
    pathname: string;
  };
}

const LoginPage = ({
  showSignup,
  setSignupform,
  artistSignup,
}: {
  showSignup: boolean;
  setSignupform: any;
  artistSignup?: boolean;
}) => {
  const { address, login } = useWallet();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // If the user is logged in and we're not already on the login page, redirect to the last viewed page or feed page.
    if (address && !location.pathname.includes("login")) {
      /* const { from } = location.state || { from: { pathname: "/" } };
      const previousLocation: Location = from as Location;
      history.replace(previousLocation); */

      window.location.reload();
    } else if (address && location.pathname.includes("login")) {
      history.replace("/");
    }
  }, [address, history, location.pathname, location.state]);

  /*   useEffect(() => {
    console.log("location", location);
  }, []); */

  useEffect(() => {
    // show loader for a few seconds while cookie is checked
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const fblogin = () => {
    const url = REACT_APP_SOCKET_URL + "/login/federated/facebook";
    const newWindow = window.open(url, "name", "height=600,width=600");
    newWindow?.postMessage(JSON.stringify({ obj: window }), "*");
    if (window.focus) {
      newWindow.focus();
    }
    return false;
  };

  const loopsPolicy = () => {
    return (
      <span>
        By continuing, you agree to the{" "}
        <a href="https://awards.loop.fans/Terms-of-service" target="_blank">
          Terms of Service
        </a>{" "}
        and{" "}
        <a href="https://awards.loop.fans/Privacy-Policy" target="_blank">
          Privacy Policy
        </a>
        .{" "}
      </span>
    );
  };

  return (
    <div
      style={
        location.pathname.includes("/login")
          ? {
              width: "100%",
              height: "fit-content",
              margin: "auto",
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }
          : {}
      }
    >
      {loading ? (
        <>
          <div className="loader" />
        </>
      ) : showSignup ? (
        <>
          <div
            className={styles.wallet_login_wrapper}
            style={{ width: "400px" }}
          >
            <div className="btn_box">
              <div className={styles.loginbtn}>
                <LoginWithGoogle onClick={login} text="Connect with Google" />
              </div>

              <hr />
              {loopsPolicy()}

              {/* <div className={styles.divider}>
                <span>or</span>
              </div>
              <Button
                className={styles.signup}
                onClick={() => setSignupform(false)}
              >
                Login to Loop Fans
              </Button> */}
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={styles.wallet_login_wrapper}
            style={{ width: "400px" }}
          >
            <div className="btn_box">
              <div className={styles.loginbtn}>
                <LoginWithGoogle onClick={login} text="Connect with Google" />
              </div>

              <hr />
              {loopsPolicy()}

              {/* <div className={styles.loginbtn}>
                <LoginWithFB onClick={fblogin} text="Log in with Facebook" />
              </div> */}

              {/* <div className={styles.divider}>
                <span>or</span>
              </div>
              <Button
                className={styles.signup}
                onClick={() => setSignupform(true)}
              >
                New to Loop Fans? Join Now
              </Button> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LoginPage;
