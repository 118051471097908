import { useEffect, useState } from "react";
import Modal from "../Modal";
import Button from "../button/button";
import styles from "./login.module.scss";
import LoginPage from "app/pages/Login/Login";
import { useHook } from "app/hooks/common";

import logo from "../../../assets/logo.png";
import checkmark from "../../../assets/icons/check.svg";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { useLogin } from "app/context/loginProvider";

interface LoginProps {
  showLoginModal: boolean;
  showSignup: boolean;
  artistSignup?: boolean;
}

export default function LoginModal(props: LoginProps) {
  const { showLoginModal, showSignup, artistSignup } = props;
  const { address } = useHook();
  const { toggleLoginModal, toggleSignupModal } = useLogin();
  const checkList = t("signupCheckList");

  /*   useEffect(() => {
    // Update signupForm state when showSignup prop changes
    toggleSignupModal();
  }, [showSignup]);
 */
  useEffect(() => {
    if (address && showLoginModal) {
      toggleLoginModal();
    }
  }, [address]);

  return (
    <div className={styles.login_modal}>
      <Modal
        isOpen={showLoginModal}
        title=""
        onClose={() => {
          toggleLoginModal();
          //toggleSignupModal();
        }}
        customClass="pauseModal"
        login={true}
      >
        <div
          style={{ textAlign: "center", marginBottom: "50px" }}
          className="pauseCard"
        >
          <img className={styles.logo} src={logo} alt="logo" />
          <h3 />
          {!artistSignup &&
            (showSignup ? (
              <>
                <h3>
                  <Trans>signupTitle</Trans>
                </h3>
                <p>Get free access to explore VIP Clubs and special benefits</p>
                <br />

                <div className={styles.list}>
                  <p>
                    <img src={checkmark} /> {checkList[0]}
                  </p>
                  <p>
                    <img src={checkmark} /> {checkList[1]}
                  </p>
                </div>

                <br />
                <br />
              </>
            ) : (
              <>
                <h3>
                  <Trans>loginTitle</Trans>
                </h3>
                <br />
                <br />
              </>
            ))}
          <div className={styles.content}>
            <LoginPage
              artistSignup={artistSignup}
              showSignup={showSignup}
              setSignupform={toggleSignupModal}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
