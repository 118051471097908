import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import "./index.scss";
import App from "./App";
import { WalletProvider } from "./app/Loop_Reuseable_Component/packages/loop_wallet";
import { UserProvider } from "app/context/userInfo";
import ErrorBoundary from "ErrorBoundary";
import { FeedContextProvider } from "app/context/feedContext";
import "app/util/i18n.ts";
import { LoginContextProvider } from "app/context/loginProvider";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <ErrorBoundary>
      <WalletProvider>
        <LoginContextProvider>
          <UserProvider>
            <FeedContextProvider>
              <App />
            </FeedContextProvider>
          </UserProvider>
        </LoginContextProvider>
      </WalletProvider>
    </ErrorBoundary>
  </BrowserRouter>
);
