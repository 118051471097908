import { useState } from 'react';
import { FaPlayCircle } from "react-icons/fa";
import { FaPauseCircle } from "react-icons/fa";
import styles from "./Mastering.module.scss";

import ReactAudioPlayer from 'react-audio-player';

const MasteringPlayer = ({song})=>{
    const [showMastered,setShowMastered] = useState(null)
    const [showOrignal,setShowOrignal] = useState(null)
	return (<>
        <div  className={styles.collapseItem} >
        {!showMastered ?
        <>
        <FaPlayCircle size={25} onClick={()=>{
          setShowMastered(!showMastered);
          if(showOrignal){
            setShowOrignal(!showOrignal)
          }}}/><span className={styles.nameMastering}> {song.name}(mastered)</span>
        </>
        :
        <>
        <FaPauseCircle size={25} onClick={()=>setShowMastered(!showMastered)}/><span className={styles.nameMastering}> {song.name}(mastered)</span>
        </>
        }
        {
          showMastered &&
          <ReactAudioPlayer
            src={song.mastering_url}
            controls
            autoPlay
            style={{marginLeft: '10%',display: 'none'}}
          />
        }
      </div>
      <div  className={styles.collapseItem} >
        {!showOrignal ?
        <>
         <FaPlayCircle size={25} onClick={()=>{
          setShowOrignal(!showOrignal)
          if(showMastered){
            setShowMastered(!showMastered)
          }}
        }/><span className={styles.nameMastering}> {song.name}(Orignal)</span>
        </>
        :
        <>
        <FaPauseCircle size={25} onClick={()=>setShowOrignal(!showOrignal)}/><span className={styles.nameMastering}> {song.name}(Orignal)</span>
       </>
        }
        {
          showOrignal &&
          <ReactAudioPlayer
            src={song.orginalTrackUrl}
            controls
            autoPlay
            style={{marginLeft: '10%',display: 'none'}}
          />
        }
      </div>
    </>

	)
}
export default MasteringPlayer