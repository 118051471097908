import React, { useEffect, useState } from "react";
import Modal from "app/components/Modal";
import Button from "app/components/button/button";
import { file } from "jszip";
import { deleteBenefit } from "app/helpers/CreateDeleteBenefits";

export default function DeleteBenefitsModal({
  deleteAlbum,
  setDeleteAlbum,
  deleteVideo,
  setDeleteVideo,
  deleteFile,
  setDeleteFile,
  cookie,
  videoCollectionId,
  albumId,
  fileId,
  setFetchTrigger,
  setCollectionBenefits,
}: {
  deleteAlbum?: boolean;
  deleteVideo?: boolean;
  deleteFile?: boolean;
  setDeleteAlbum?: (value: any) => void;
  setDeleteVideo?: (value: any) => void;
  setDeleteFile?: (value: any) => void;
  setFetchTrigger?: (value: any) => void;
  setCollectionBenefits?: (value: any) => void;
  cookie: string;
  videoCollectionId: string;
  albumId: string;
  fileId?: string;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [benefitId, setBenefitId] = useState<string>("");
  const [benefitType, setBenefitType] = useState<string>("");

  useEffect(() => {
    if (deleteAlbum) {
      setBenefitId(albumId);
      setBenefitType("album");
    } else if (deleteVideo) {
      setBenefitId(videoCollectionId);
      setBenefitType("video");
    } else if (deleteFile) {
      setBenefitId(fileId);
      setBenefitType("files");
    }
  }, [deleteAlbum, deleteVideo, deleteFile]);

  const handleDeleteBenefit = async () => {
    try {
      setLoading((prev) => !prev);
      const response = await deleteBenefit(cookie, benefitType, benefitId);
      setFetchTrigger((prev) => !prev);
      setLoading((prev) => !prev);
      if (deleteAlbum) {
        setDeleteAlbum(false);
      } else if (deleteVideo) {
        setDeleteVideo(false);
      } else if (deleteFile) {
        setDeleteFile(false);
      }
    } catch (error) {
      console.error(error);
      setLoading((prev) => !prev);
    }
  };

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        if (deleteAlbum) {
          setDeleteAlbum(false);
        } else if (deleteVideo) {
          setDeleteVideo(false);
        } else if (deleteFile) {
          setDeleteFile(false);
        }
      }}
      title={"Delete Benefit"}
      customSubClass="defaultModal"
    >
      <div className="defaultModalContent">
        <span>
          Are you sure to delete{" "}
          {deleteAlbum
            ? "Album"
            : deleteVideo
            ? "Video"
            : deleteFile
            ? "File"
            : null}{" "}
          benefit?
        </span>
        <div className="defaultModalButtons">
          <Button
            size="sm"
            onClick={() => handleDeleteBenefit()}
            disabled={loading}
            loading={loading}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
}
