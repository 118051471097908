import React from "react";
import styles from "./Collections.module.scss";
import { useHistory } from "react-router-dom";

export default function Collections() {
  const history = useHistory();
  const handleSubItems = (to) => {
    history.push(to);
  };

  return (
    <div className={styles.collections_container}>
      <h1>Collectibles</h1>
      <span>
        Go to Dashboard and create new collections check your Profits, explore
        your NFTs and Collections that are in Draft or the ones that are Live
      </span>
      <div className={styles.collections_options}>
        <div
          className={styles.collections_option}
          onClick={() => handleSubItems("/")}
        >
          <h2>Dashboard</h2>
          <p>Create new Collections, check your revenues & request payouts</p>
        </div>
        <div
          className={styles.collections_option}
          onClick={() => handleSubItems("/inDraft/")}
        >
          <h2>In Draft Collections</h2>
          <p>Click here to check your "in Draft" Collections</p>
        </div>
        <div
          className={styles.collections_option}
          onClick={() => handleSubItems("/liveCollections/")}
        >
          <h2>Live Collections</h2>
          <p>Check here to view your Live Collections</p>
        </div>
      </div>
    </div>
  );
}
