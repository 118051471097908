import { Trans } from 'react-i18next';
import Modal from '../Modal';
import styles from './notification.module.scss';

const InProgressError = ({ setTheme }: { setTheme: any }) => {
  function onClose() {
    setTheme({
      type: undefined,
      subType: undefined,
    });
  }

  return (
    <Modal isOpen={true} title='' onClose={() => onClose()} zIndex={4} center>
      <div className={styles.notification_content}>
        <div className={styles.image_content}>
          <p className={styles.title}><Trans>TransactionDelayTitle</Trans></p>
          <div style={{ textAlign: 'center', marginBottom: '50px' }}>
            <Trans>TransactionDelayMessage</Trans>
          </div>
          {/* <button onClick={() => onClose()}>CLOSE</button> */}
        </div>
      </div>
    </Modal>
  );
};

export default InProgressError;
