import axios from "axios";

export const createMastringProject = async (cookie, data) => {
  const { name, genre, songFile, songImage } = data;
  try {
    const formData = new FormData();
    formData.append('cookie', cookie);
    formData.append('trackData', songFile);
    formData.append('cover', songImage);
    formData.append('name', name);
    formData.append('musicalStyle', genre);
    const response = await axios.post(
      `${process.env.REACT_APP_MIDDLEWARE_ADSERVER}roex/project`,
      formData
    );

    return response.data;
  } catch (error) {
    console.log("error.response.data Funtions API", error.response.data);
    throw error.response.data;
  }
};

export const createMasteringTask = async (cookie,projectId,desiredLoudness) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_MIDDLEWARE_ADSERVER}roex/mastering/${projectId}`,{cookie,desiredLoudness}
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const PullingSingleMastering = async (cookie,projectId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_MIDDLEWARE_ADSERVER}roex/pulling/${projectId}`,{cookie}
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const fetchMasterings = async (cookie) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_MIDDLEWARE_ADSERVER}roex/masterings`,
      { cookie }
    );
    const data = response.data
    return data.mastering;
  } catch (error) {
    throw error.response.data;
  }
};
