import axios from "axios";
import { toSvg } from "html-to-image";
import styles from "../pages/Create/Create.module.scss";

export const setGenreAndDivision = async () => {
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_MIDDLEWARE}arena/divisions`,
    });
    const responseGeners = await axios({
      method: "get",
      url: `${process.env.REACT_APP_MIDDLEWARE}arena/genres`,
    });

    return [response, responseGeners];
  } catch (error) {
    console.error(error);
  }
};

export const createVoteNFT = async (
  genre: string,
  division: string,
  nftImage: string,
  cookie: string,
  songFile: string,
  status: string
) => {
  const formData = new FormData();
  formData.append("genreId", genre);
  formData.append("divisionId", division);
  formData.append("image", nftImage);
  formData.append("status", status);
  formData.append("cookie", cookie);
  formData.append("song", songFile);

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_MIDDLEWARE}launchpad/createVoteCollection`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const createCollection = async (
  collectionImage: any,
  cookie: string,
  collection: any
) => {
  const formData = new FormData();
  formData.append("artwork", collectionImage as Blob);
  formData.append("cookie", cookie);
  formData.append("collection", collection);

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_MIDDLEWARE}launchpad/createCollection`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const editCollection = async (
  collectionImage: any,
  cookie: string,
  collection: any,
  id: string
) => {
  const formData = new FormData();
  formData.append("image", collectionImage as Blob);
  formData.append("cookie", cookie);
  formData.append("collection", collection);

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_MIDDLEWARE}launchpad/editCollection/${id}`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const createAlbum = async (
  fansCollectionId: string,
  cookie: string,
  query: any,
  tracks: any
) => {
  const formData = new FormData();
  formData.append("collection_id", fansCollectionId);
  formData.append("cookie", cookie);
  formData.append("type", "create");
  formData.append("query", query);
  tracks.forEach((track) => {
    formData.append("file", track.file);
  });

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_MIDDLEWARE}benefit/album`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const editCollectionAlbumEdit = async (
  fansCollectionId: string,
  albumId: string,
  cookie: string,
  query: any,
  tracks: any
) => {
  const formData = new FormData();
  formData.append("collection_id", fansCollectionId);
  formData.append("album_id", albumId);
  formData.append("cookie", cookie);
  formData.append("type", "update");
  formData.append("query", query);
  tracks.forEach((track) => {
    formData.append("file", track.file);
  });

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_MIDDLEWARE}benefit/album`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const editTrackName = async (
  cookie: string,
  trackId: string,
  trackName: string
) => {
  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_MIDDLEWARE}file/rename`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { cookie: cookie, file_id: trackId, file_name: trackName },
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const editVoteNFT = async (
  genre: string,
  division: string,
  fileToSend: string,
  type: string,
  cookie: string,
  id: string,
  status: string
) => {
  const formData = new FormData();
  formData.append("genreId", genre);
  formData.append("divisionId", division);
  formData.append("type", type);
  formData.append("song", fileToSend);
  formData.append("status", status);
  formData.append("cookie", cookie);
  formData.append("launchpadId", id);

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_MIDDLEWARE}launchpad/editVoteCollection`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const convertElementToSvgFile = async (elementRef) => {
  try {
    // Convert the HTML element to SVG data URL
    const dataUrl = await toSvg(elementRef.current, {
      cacheBust: false,
      style: styles,
    });

    // Fetch the SVG data URL and convert it to a Blob
    const response = await fetch(dataUrl);
    const blob = await response.blob();

    // Create a File from the Blob
    const fileName = "nft.svg";
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to convert HTML element to SVG file.");
  }
};
