import { Link } from "react-router-dom";
//import { Button } from "semantic-ui-react";
import classNames from "classnames";
import styles from "./input.module.scss";
import send_icon from "../../../assets/icons/send.svg";
import send_icon_active from "../../../assets/icons/send_active.svg";

interface InputProps {
  classes?: any;
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  setValue?: any;
  onSubmit?: any;
  showIcon?: boolean;
}

export default function Input(props: InputProps) {
  const { classes, disabled, placeholder, value, setValue, onSubmit, showIcon } = props;
  var sanitizedText = (text) => text?.replace(/(\r\n|\n|\r)/gm, "");

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.keyCode === 13 && !event.shiftKey && onSubmit) {
      event.preventDefault();
      onSubmit();
      setValue(""); // Clear the input field after submition
    } else if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
    }
  };

  return (
    <div className="textarea_container">
      {showIcon && (
        <div
          className="send_icon"
          onClick={() => {
            onSubmit();
            setValue("");
          }}
        >
          <img src={value ? send_icon_active : send_icon} />
        </div>
      )}
      <textarea
        disabled={disabled}
        style={{ margin: "auto" }}
        className={classNames(classes, styles.input)}
        placeholder={placeholder}
        value={sanitizedText(value)}
        onChange={(event) => setValue(sanitizedText(event.target.value))}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}
