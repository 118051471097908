import Button from "app/components/button/button";
import styles from "./LoginWithGoogle.module.scss";
import classNames from "classnames";
const LoginWithGoogle = (props) => {
  return (
    <Button {...props} className={classNames(styles.googleBtn, props?.className)}>
      <div className={classNames(styles.googleIconWrapper, "wrapper")}>
        <div className={styles.googleIcon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <title>google</title>
            <g fill="#ffffff">
              <path
                d="M23.507,9.818H12.052v4.909h6.492C17.507,18,14.944,19.091,12,19.091a7.091,7.091,0,1,1,4.553-12.52l3.567-3.4A12,12,0,1,0,12,24C18.617,24,24.6,19.636,23.507,9.818Z"
                fill="#ffffff"
              ></path>
            </g>
          </svg>
        </div>
      </div>
      <div className={styles.text}>
        <p className={styles.btnText}>
          <b>{props.text}</b>
        </p>
      </div>
    </Button>
  );
};
export default LoginWithGoogle;
