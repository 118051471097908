import Modal from "app/components/Modal";
import styles from "./CreateCollection.module.scss";
import Button from "app/components/button/button";
import { blue } from "@mui/material/colors";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import ClearIcon from "@mui/icons-material/Clear";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

export default function AddTracksModal({
  addSong,
  setAddSong,
  genres,
  songFile,
  refSong,
  handleMusicFile,
}) {
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [albumName, setAlbumName] = useState(null);
  const [genre, setGenre] = useState(null);
  const [tracks, setTracks] = useState([]);
  const maxFileSize = 25 * 1024 * 1024; // 25MB

  // funtion to delete thet tracks
  const handleDeleteTrack = (index) => {
    setTracks((prevTracks) => prevTracks.filter((_, i) => i !== index));
  };

  // when all the values are true set ready to true
  useEffect(() => {
    setReady(!!albumName && !!genre && tracks.length > 0);
  }, [albumName, genre, tracks, handleDeleteTrack]);

  // validate that the size of the track its less than 25 MB
  function TrackSizeValidator(file) {
    if (file.size > maxFileSize) {
      return {
        code: "name-too-large",
        message: `Song size exceeds the maximum allowed size of 25MB.`,
      };
    }
    return null;
  }

  /* ====================== */
  /* Create the track and store the at the array of tracks  */
  /* ======== Start ======= */
  const onDrop = useCallback((acceptedFiles) => {
    const newTracks = acceptedFiles.map((file) => ({
      file,
      name: file.name,
      size: file.size,
    }));
    setTracks((prevTracks) => [...prevTracks, ...newTracks]);
  }, []);

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept: {
        "audio/*": [".mp3", ".mpeg"],
      },
      maxFiles: 10,
      validator: TrackSizeValidator,
    });
  /* ====================== */
  /* Create the track and store the at the array of tracks */
  /* ======== End ======= */

  // store the tracks that are not availible
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  // store the genre selected
  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    setGenre(value);
  };

  /* ====================== */
  /* create the track album of the Collection */
  /* ======== Start ======= */
  const createCollectionAlbum = async () => {
    try {
    } catch (error) {
      console.error(error);
    }
  };
  /* ====================== */
  /* create the track album of the Collection  */
  /* ======== End ======= */

  return (
    <Modal
      isOpen={addSong}
      onClose={() => setAddSong((prev) => !prev)}
      title={"Add Tracks"}
      customSubClass="defaultModal"
    >
      <div className="defaultModalContent">
        <div className={styles.album_infos}>
          <div className={styles.input_label}>
            <span>Album Name</span>
            <div className={styles.input_song}>
              <input
                type="text"
                onChange={(e) => setAlbumName(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.input_label}>
            <span>Genre</span>
            <div className={styles.input_song}>
              <select
                name="genre"
                id="genre"
                required
                onChange={handleRegisterChange}
                value={genre}
              >
                {genres &&
                  genres?.map((gen) => (
                    <option key={gen.id} value={gen.id}>
                      {gen.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        {songFile ? (
          <div className={styles.album_infos}>
            <div className={styles.input_label}>
              <span>Tracks</span>
              <div className={styles.input_song}>
                <input
                  type="text"
                  onChange={(e) => setAlbumName(e.target.value)}
                  placeholder="Track Name"
                />
              </div>
            </div>
            <div className={styles.input_label}>
              <span></span>
              <div className={styles.input_song}>
                <LibraryMusicIcon sx={{ color: blue[700] }} />
                <input
                  type="file"
                  ref={refSong}
                  hidden
                  onChange={handleMusicFile}
                  accept="audio/mp3"
                />
                <div className={styles.input_song_title}>
                  <span>{songFile ? "song slected" : "No file selected"}</span>

                  <small>(Max: 25MB)</small>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className={styles.drag_drop_contianer}>
          <div {...getRootProps()} className={styles.drag_drop}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <div>
                <p>Drag 'n' drop your tracks, or click to select files</p>
                <em>(10 files Max - 25MB each)</em>
              </div>
            )}
          </div>
          {tracks.length > 0 ? (
            <div className={styles.track_list_container}>
              <div className={styles.track_list}>
                <h4>Tracks:</h4>
                {tracks.map((track, index) => (
                  <div key={index} className={styles.track_item}>
                    <span>{track.name}</span>

                    <ClearIcon
                      onClick={() => handleDeleteTrack(index)}
                      sx={{
                        color: blue[700],
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                    />
                  </div>
                ))}
              </div>
              {fileRejections.length > 0 ? (
                <div className={styles.track_list}>
                  <h4>Rejected files</h4>
                  <ul>{fileRejectionItems}</ul>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>

        <div className="defaultModalButtons">
          <Button
            size="sm"
            onClick={createCollectionAlbum}
            disabled={!ready}
            loading={loading}
          >
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
}
