import { createContext } from 'react';
import { ContextApi } from './types';

// @ts-ignore
export const NotificationContext = createContext<ContextApi>({
  theme: {
    type: undefined,
    subType: undefined,
    txLogs: undefined
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTheme: () => {},
});
