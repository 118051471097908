import React, { useEffect, useState } from "react";
import styles from "./Insights.module.scss";
import InsightsItem from "./InsightsItem";
import Button from "app/components/button/button";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { useHook } from "app/hooks/common";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { DirectusImage } from "app/core/common";
import Loader from "app/components/Loader";

export default function Insights() {
  const { cookie } = useHook();
  const [collectionInfo, setCollectionInfo] = useState(null);
  const [transactionsList, setTransactionsList] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const id = location.pathname.split("/")[2];

  /* ====================== */
  /* Get Launchpad information */
  /* ======== Start ======= */
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await axios({
          url: `${process.env.REACT_APP_MIDDLEWARE}fans/fans_launchpad/insights/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
            user_cookie: cookie,
          },
        });

        setCollectionInfo(result);
        setLoading(false);
      } catch (error) {
        history.push(`/`);
        console.error(error);
      }
    };
    fetchData();
  }, [cookie, history, id]);
  /* ====================== */
  /* Get Launchpad information */
  /* ======== End ======= */

  /* ====================== */
  /* Get Collection buyers information */
  /* ======== Start ======= */

  useEffect(() => {
    const fetchData = async () => {
      try {
        const list = await axios({
          url: `${process.env.REACT_APP_MIDDLEWARE}fans/fans_launchpad/insights/transactions/${id}/1`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
            user_cookie: cookie,
          },
        });
        setTransactionsList(list);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [collectionInfo, cookie, id]);

  /* ====================== */
  /* Get Collection buyers information */
  /* ======== Start ======= */

  // send the user to in live view
  const handleViewLive = () => {
    window.open(`https://app.loop.fans/collection/${id}`, "_blank");
  };

  // send the user to edit nft view
  const handleEdit = (collectionInfo) => {
    if (
      collectionInfo?.data[0]?.collection_type === "collectible" ||
      "support"
    ) {
      history.push(`/editCollection/${id}`);
    } else if (collectionInfo?.data[0]?.collection_type === "vote") {
      history.push(`/editNftCollection/${id}`);
    } else {
      return;
    }
  };

  if (loading) {
    return (
      <div
        style={{
          position: "relative",
          height: "100%",
          width: "100%",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: "0 auto",
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.insights_container}>
      <div className={styles.insights_title}>
        <h1>Insights</h1>
      </div>
      <div className={styles.insights_infos}>
        <div className={styles.insight_image}>
          {collectionInfo?.data[0]?.artwork?.id ? (
            <LazyLoadImage
              src={DirectusImage(collectionInfo?.data[0]?.artwork?.id)}
              effect="blur"
              wrapperClassName={styles.image}
            />
          ) : null}
        </div>

        <div className={styles.insights_data}>
          <span>{collectionInfo?.data[0]?.name}</span>
          <Button size="sm" onClick={() => handleViewLive()}>
            View Live
          </Button>
          <Button size="sm" onClick={() => handleEdit(collectionInfo)}>
            <div style={{ width: "70px" }}>Edit</div>
          </Button>
        </div>
      </div>
      <div className={styles.insights_text}>
        <span>
          Gain valuable insights into your music and NFT sales. Track your
          performance, understand your audience, and optimize your strategy to
          maximize your success.
        </span>
      </div>
      <div className={styles.insights_table}>
        <div className={styles.insights_header}>
          {/* <div className={styles.insights_data}>
            <span>NFTs sold:</span>
            {collectionInfo?.data[0]?.sold}
          </div> */}
          <div className={styles.insights_data}>
            <span style={{ fontSize: "22px" }}>Total NFTs:</span>

            {collectionInfo?.data?.length >= 3
              ? collectionInfo?.data?.map((nft, index) => (
                  <span style={{ color: "gray" }} key={index}>
                    {nft?.name}: {""}
                    <span style={{ color: "white" }}>{nft?.maxSupply}</span>
                  </span>
                ))
              : collectionInfo?.data?.map((nft, index) => (
                  <span
                    style={{
                      color: "gray",
                      alignSelf: "center"
                    }}
                    key={index}
                  >
                    {/* {nft?.name}: {""} */}
                    <span style={{ color: "white", fontSize: "42px" }}>{nft?.maxSupply}</span>
                  </span>
                ))}
          </div>
          <div className={styles.insights_data}>
            <span style={{ fontSize: "22px" }}>Profit:</span>
            <span>Total NFTs Sold: {collectionInfo?.data?.[0].sold} </span>
            <span>Total Sales: ${collectionInfo?.data?.[0].total}</span>
            <span>Platform Fee: -12% </span>
            <div
              style={{ borderBottom: "1px solid gray", width: "100%" }}
            ></div>
            <p>
              $
              {collectionInfo
                ? collectionInfo?.data
                    .reduce(
                      (totalProfit, nft) =>
                        totalProfit + parseFloat(nft?.profit),
                      0
                    )
                    .toFixed(2)
                : "0.00"}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.insights_list}>
        {transactionsList?.data?.length > 0 ? (
          <table className={styles.transactions_table}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Collection</th>
                <th>User</th>
                <th>Quantity</th>
                <th>Total Sale</th>
              </tr>
            </thead>
            <tbody>
              {transactionsList?.data?.map((user, index) => (
                <InsightsItem key={index} user={user} />
              ))}
            </tbody>
          </table>
        ) : (
          <span style={{ color: "gray" }}>No sales registered</span>
        )}
      </div>
    </div>
  );
}
