import React from "react";
import DOMPurify from "dompurify"; // Import DOMPurify for sanitizing HTML

export function truncate(
  text: string,
  length: number,
  ending: string = "...more",
  onClickMore?: () => void
): JSX.Element {
  let moreElement = null;
  let displayText = text;

  const urlRegex =
    /(\bhttps?:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
  const urls = text.match(urlRegex) || [];

  urls.forEach((url) => {
    const startIndex = text.indexOf(url);
    const endIndex = startIndex + url.length;
    if (length > startIndex && length < endIndex) {
      length = endIndex;
    }
  });

  if (text.length > length) {
    displayText = text.substring(0, length - ending.length);
    moreElement = (
      <span className={onClickMore ? "moreTextOpen" : ""} onClick={onClickMore}>
        {ending}
      </span>
    );
  }

  // Function to handle link click
  const handleLinkClick = (url, e) => {
    e.preventDefault();
    window.open(url, "_blank", "noopener,noreferrer");
    /* if (
      window.confirm(
        "You are about to leave Loop Fans and visit an external website. Do you wish to continue?"
      )
    ) {
      window.open(url, "_blank", "noopener,noreferrer");
    } */
  };

  // Split text into segments of URLs and non-URLs
  const parts = displayText.split(urlRegex);
  const urlMaxLen = 35;

  // Sanitize each non-URL part
  const sanitizedParts = parts.map((part, index) =>
    urlRegex.test(part) ? (
      <a
        href={part}
        key={index}
        onClick={(e) => handleLinkClick(part, e)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {part.length > urlMaxLen ? (
          <>{part.substring(0, urlMaxLen)}...</>
        ) : (
          part
        )}
      </a>
    ) : (
      <span
        key={index}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(part) }}
      />
    )
  );

  return (
    <div className="moreText">
      {sanitizedParts}
      {moreElement}
    </div>
  );
}

export const setParam = (name: string, value: any) => {
  const url = new URL(window.location.href);
  url.searchParams.delete("postTab");
  url.searchParams.set(name, value);
  return window.history.replaceState(null, ``, url.href);
};

export const getParam = (name: any) => {
  const urlParams = new URLSearchParams(window.location.search);
  const param = urlParams.get(name);
  return param ? param : null;
};

export const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(0) + "m";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(0) + "k";
  } else {
    return num;
  }
};
