import React, { useEffect, useState } from "react";
import styles from "./Studio.module.scss";
import { options } from "./StudioOptions";
import StudioOptionBox from "./StudioOptionBox";
import Modal from "app/components/Modal";
import Button from "app/components/button/button";
import { useHook } from "app/hooks/common";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  fetchBalance,
  fetchPymentHistory,
  requestPayout,
} from "app/helpers/payment";

export default function Studio() {
  const [openPayout, setOpenPayout] = useState<boolean>(false);
  const [paymentOption, setPaymentOption] = useState<string>("");
  const [paypalEmail, setPaypalEmail] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [whatsappNumber, setWhatsappNumber] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [pendingPayment, setPendingPayment] = useState<boolean>(false);
  const [showPayments, setShowPayments] = useState<boolean>(false);
  const [balance, setBalance] = useState<number | null>(null);
  const [paymentHistory, setPaymentHistory] = useState<Array<any>>([]);
  const [notPayment, setNotPayment] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>("");
  const { address, cookie, userInfo } = useHook();

  console.log(cookie);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const balance = await fetchBalance(cookie);
        setBalance(balance?.data?.balance);
        const payment_history = await fetchPymentHistory(cookie);
        setPaymentHistory(payment_history?.data);
        if (
          payment_history?.data.some((payment) => payment.status === "pending")
        ) {
          setPendingPayment(true);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [cookie, pendingPayment]);

  const handleOpenPayout = () => {
    if (balance < 20 || balance === undefined || pendingPayment) {
      setNotPayment(true);
    } else {
      setOpenPayout(true);
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleRequestPayout = async () => {
    if (!paypalEmail || !fullName || !amount || !whatsappNumber) {
      setError(
        "Please provide all required information and ensure amount is at least $20."
      );
      return;
    } else if (!validateEmail(paypalEmail)) {
      setError("Please provide a valid PayPal email.");
      return;
    } else {
      setError("");
      try {
        setLoading(true);
        const requestPayment = await requestPayout(
          cookie,
          amount,
          paymentOption,
          paypalEmail,
          fullName,
          whatsappNumber
        );

        setPendingPayment(true);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        setOpenPayout(false);
      }
    }
  };

  const handlePayment = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setPaymentOption(value);
    setError("");
  };

  useEffect(() => {
    if (openPayout) {
      document.body.style.overflow = "hidden"; // Prevent scrolling
    } else {
      document.body.style.overflow = "auto"; // Allow scrolling
    }

    return () => {
      document.body.style.overflow = "auto"; // Reset on component unmount
    };
  }, [openPayout]);

  return (
    <div className={styles.container}>
      {userInfo ? (
        <div className={styles.title_request}>
          {pendingPayment ? (
            <span>
              Current Balance: ${balance === undefined ? "0" : balance}
            </span>
          ) : (
            <span>
              Current Balance: ${balance === undefined ? "0" : balance}
            </span>
          )}

          <button
            className={pendingPayment ? styles.payment_off : styles.payment_on}
            onClick={handleOpenPayout}
          >
            {pendingPayment ? "Pending Payout" : "Request Payout"}
          </button>
        </div>
      ) : null}
      {userInfo ? (
        <div
          className={`${styles.payment_history} ${
            showPayments ? styles.marginBottom20 : styles.marginBottom40
          }`}
          onClick={() => setShowPayments((prev) => !prev)}
        >
          <span>Payment history</span>
          {showPayments ? (
            <KeyboardArrowDownIcon />
          ) : (
            <KeyboardArrowRightIcon />
          )}
        </div>
      ) : null}

      {showPayments && (
        <div className={styles.payment}>
          <table className={styles.payment_table}>
            <thead>
              <tr>
                <th>Status</th>

                <th>Amount</th>
                <th>Platform</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {paymentHistory?.map((payment) => (
                <tr key={payment.id}>
                  <td
                    style={{
                      color: payment.status === "completed" ? "green" : null,
                    }}
                  >
                    {payment?.status}
                  </td>

                  <td>${payment?.amount}</td>
                  <td>{payment?.platform}</td>
                  <td>
                    {new Date(payment?.date_requested).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <hr />

      <div className={styles.title}>
        <span>What would you like to do next?</span>
      </div>
      <div className={styles.options_container}>
        {options?.map((option, index) => (
          <StudioOptionBox option={option} key={index} />
        ))}
      </div>
      <Modal
        isOpen={openPayout}
        onClose={() => {
          setOpenPayout(false);
          setPaymentOption("");
          setPaypalEmail("");
          setFullName("");
          setWhatsappNumber("");
          setAmount("");
          setError("");
        }}
        title={"Request Payout"}
        customSubClass="defaultModal"
      >
        <div className="defaultModalContent">
          <div className={styles.info_payment}>
            <label htmlFor="fullName">Full name*</label>
            <input
              id="fullName"
              className={styles.select}
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Enter Full Name"
            />
          </div>

          <div className={styles.info_payment}>
            <label htmlFor="whatsappNumber">WhatsApp*</label>
            <input
              id="whatsappNumber"
              className={styles.select}
              value={whatsappNumber}
              onChange={(e) => setWhatsappNumber(e.target.value)}
              type="number"
              placeholder="Enter WhatsApp Number"
            />
          </div>
          <hr />
          <span>Select which payment method you would like to use:</span>
          <select
            name="payment"
            onChange={handlePayment}
            className={styles.select}
            placeholder={"Select a payment option"}
          >
            <option value="">select</option>
            <option value="paypal">PayPal</option>
          </select>

          {paymentOption === "paypal" && (
            <div className={styles.info_payment}>
              <label htmlFor="paypalEmail">PayPal Email</label>
              <input
                id="paypalEmail"
                className={styles.select}
                value={paypalEmail}
                onChange={(e) => setPaypalEmail(e.target.value)}
                type="email"
                placeholder="Enter PayPal Email"
              />
            </div>
          )}
          <div className={styles.info_payment}>
            <label htmlFor="amount">Requested Amount</label>
            <input
              id="amount"
              className={styles.select}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              type="number"
              placeholder="$0.00"
            />
          </div>

          {error && <div className={styles.error}>{error}</div>}
          <div className="defaultModalButtons">
            <Button
              size="sm"
              onClick={handleRequestPayout}
              disabled={loading}
              loading={loading}
            >
              Request Payout
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={notPayment}
        onClose={() => setNotPayment(false)}
        title={"Can't Request Payout"}
        customSubClass="defaultModal"
      >
        <div className="defaultModalContent">
          <span style={{ padding: "40px", fontSize: "20px" }}>
            {!pendingPayment
              ? "You need to have at least $20 in your balance to request a payout"
              : "You already have a pending payout. Please wait for it to be processed before requesting another one."}
          </span>
        </div>
        <div className="defaultModalContent">
          <div className="defaultModalButtons">
            <Button size="sm" onClick={() => setNotPayment(false)}>
              Ok
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
