import "./App.scss";
import Sidebar from "./sidebar/Sidebar";
import { Route, Switch, useLocation } from "react-router-dom";
import AuthenticatedRoute from "AuthenticatedRoute";
import LoginPage from "app/pages/Login/Login";
import { NotificationContext } from "app/context/notification";
import { useCallback, useEffect, useRef, useState } from "react";
import NotificationModals from "app/components/notificationModals";
import ScrollToTop from "ScrollToTop";
import { useHook } from "app/hooks/common";
import LoginModal from "app/components/loginModal/LoginModal";
import { useLogin } from "app/context/loginProvider";
import Modal from "app/components/Modal";
import { Trans } from "react-i18next";
import Button from "app/components/button/button";
import { t } from "i18next";
import axios from "axios";
import { isCreator } from "app/core/common";
import { includes } from "ramda";
import Studio from "app/pages/Studio/Studio";
import Create from "app/pages/Create/Create";
import InDraft from "app/pages/Nft_Collections/In_Draft/InDraft";
import Live from "app/pages/Nft_Collections/Live_Collections/Live";
import Mastering from "app/pages/Mastering/Mastering";
import ReleaseMusic from "app/pages/CreateCollection/CreateCollection";
import EditCollection from "app/pages/editCollection/EditCollection";
import EditNft from "app/pages/EditNFT/EditNft";
import PaymentModule from "app/pages/Mastering/Payment/Payment";
import Collections from "app/pages/collections/Collections";
import Insights from "app/pages/Insights/Insights";
import Aitools from "app/pages/Aitools/Aitools";

/* interface BugpilotOptions {
  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  subscriptionStatus?: string;
} */

/* declare global {
  interface Window {
    Bugpilot: {
      identify: (options: BugpilotOptions) => void;
      logout: () => void;
    };
  }
} */

function App() {
  const { address, cookie, userInfo } = useHook();
  const [type, setType] = useState(undefined);
  const [subType, setSubType] = useState(undefined);
  const [txHash, setTxHash] = useState(undefined);
  const [onOk, setOnOk] = useState(undefined);
  const [txErrorMessage, setTxErrorMessage] = useState(undefined);
  const [redirectURL, setRedirectURL] = useState("");
  const [themeData, setThemeData] = useState<any>({ theme: {} });
  const location = useLocation();

  const setTheme = useCallback((theme) => {
    setThemeData({ theme: theme });
    setType(theme?.type);
    setSubType(theme?.subType);
    setTxHash(theme?.txHash);
    setOnOk(theme?.onOk);
    setTxErrorMessage(theme?.txErrorMessage);
    if (theme?.redirectURL !== undefined) {
      setRedirectURL(theme?.redirectURL);
    }
  }, []);

  /* useEffect(() => {
    if (window.Bugpilot && userInfo) {
      window.Bugpilot.identify({
        id: userInfo.id,
        firstName: userInfo.first_name,
        subscriptionStatus: "active",
      });
    }
  }, [userInfo]); */

  const [state, setState] = useState<any>({
    theme: {
      type: undefined,
      subType: undefined,
      txHash: undefined,
      onOk: undefined,
      txErrorMessage: undefined,
      redirectURL: undefined,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setTheme,
  });
  useEffect(() => {
    setState((prevState: any) => ({
      ...prevState,
      theme: {
        type,
        subType,
        txHash,
        onOk,
        txErrorMessage,
        redirectURL,
        ...themeData.theme,
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setTheme,
    }));
  }, [
    type,
    subType,
    txHash,
    onOk,
    txErrorMessage,
    redirectURL,
    setTheme,
    themeData.theme,
  ]);

  useEffect(() => {
    const handleResize = () => {
      const isKeyboardOpen = window.innerHeight < window.screen.height;

      if (isKeyboardOpen) {
        document.body.style.height = "100vh";
        // Other styling adjustments as needed
      } else {
        document.body.style.height = "auto";
        // Reset other styles
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerRef = useRef(null);
  const { showLoginModal, showSignup, artistSignup } = useLogin();

  /* Check UserAgent on iPhone */
  /*
    let standalone = (window.navigator as any).standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);
  */

  // check UserAgent on Android
  const ua = navigator.userAgent;
  const isInstagram = ua.indexOf("Instagram") > -1 ? true : false;

  const InAppModal = () => {
    const [copy, setCopy] = useState(false);
    {
      /* IN APP DETECTION */
    }
    return (
      <Modal
        isOpen={isInstagram}
        onClose={() => {}}
        title={t("browserNotice")}
        customClass="pauseModal"
      >
        <div
          style={{ textAlign: "center", marginBottom: "50px", padding: "20px" }}
          className="pauseCard"
        >
          <Trans>embeddedWebView</Trans>
          <br />
          <br />
          <p>
            <b>Step 1:</b> <Trans>embeddedWebViewStep1</Trans>
          </p>
          <p>
            <b>Step 2:</b> <Trans>embeddedWebViewStep2</Trans>
          </p>
          <br />
          <p>
            <b>or</b>
          </p>
          <br />
          <p>
            <Trans>embeddedWebViewStep3</Trans>
          </p>
          <br />
          <br />
          {/* Show Toast on button click */}
          <Button
            className="btn_full btn_sm btn_solid"
            onClick={() => {
              navigator.clipboard.writeText(window.location.href);
              setCopy(true);
              setTimeout(() => {
                setCopy(false);
              }, 1000);
            }}
          >
            {!copy ? "Copy Link" : "Copied"}
          </Button>
          <br />
        </div>
      </Modal>
    );
  };

  // ************************* //
  // Analytics for page views
  // ************************* //
  useEffect(() => {
    // check if localhost
    const sendRouteChange = async () => {
      const isLocalhost = window.location.hostname === "localhost";
      if (cookie && !isLocalhost) {
        axios(`${process.env.REACT_APP_MIDDLEWARE}arena/pageView`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            path: location.pathname,
            cookie: cookie,
          },
        });
      } else if (
        !cookie &&
        !isLocalhost &&
        location.pathname.includes("artist-signup")
      ) {
        axios(`${process.env.REACT_APP_MIDDLEWARE}arena/pageView`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            path: location.pathname,
          },
        });
      }
    };

    sendRouteChange();
  }, [location, cookie]);

  return (
    <div className="App">
      <InAppModal />
      <ScrollToTop />
      <NotificationContext.Provider value={state}>
        <NotificationModals />
        <LoginModal
          showLoginModal={showLoginModal}
          showSignup={showSignup}
          artistSignup={artistSignup}
        />
        <Switch>
          {/* Sidebar with Routes */}
          <Route>
            <Sidebar isLoggedIn={address} containerRef={containerRef}>
              {/* Login Route */}
              <Route path="/login/" component={LoginPage} exact />
              {/* Studio */}
              <Route path="/" exact component={Studio} />
              <AuthenticatedRoute
                path="/collections"
                exact
                component={Collections}
                containerRef={containerRef}
              />
              <AuthenticatedRoute
                path="/aitools"
                exact
                component={Aitools}
                containerRef={containerRef}
              />
              <AuthenticatedRoute
                path="/collection/:id/insights"
                exact
                component={Insights}
                containerRef={containerRef}
              />
              <AuthenticatedRoute
                path="/createNFT/"
                exact
                component={Create}
                containerRef={containerRef}
              />
              <AuthenticatedRoute
                path="/createCollection/"
                exact
                component={ReleaseMusic}
                containerRef={containerRef}
              />
              <AuthenticatedRoute
                path="/editCollection/:id"
                exact
                component={EditCollection}
                containerRef={containerRef}
              />
              <AuthenticatedRoute
                path="/editNftCollection/:id"
                exact
                component={EditNft}
                containerRef={containerRef}
              />
              <AuthenticatedRoute
                path="/inDraft/"
                exact
                component={InDraft}
                containerRef={containerRef}
              />

              <AuthenticatedRoute
                path="/liveCollections/"
                exact
                component={Live}
                containerRef={containerRef}
              />
              <AuthenticatedRoute
                path="/mastering"
                exact
                component={Mastering}
                containerRef={containerRef}
              />
              <AuthenticatedRoute
                path="/price"
                exact
                component={PaymentModule}
                containerRef={containerRef}
              />
            </Sidebar>
          </Route>
        </Switch>
      </NotificationContext.Provider>
    </div>
  );
}

export default App;
