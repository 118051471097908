import React, { useEffect, useRef, useState } from "react";
import styles from "./Mastering.module.scss";
import Input from "app/components/input/input";
import Button from "app/components/button/button";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import Modal from "app/components/Modal";
import axios from "axios";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import ImageIcon from "@mui/icons-material/Image";
import { blue } from "@mui/material/colors";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import InfoIcon from "@mui/icons-material/Info";
import { truncate } from "app/helpers/helper";
import { PullingSingleMastering, createMasteringTask, createMastringProject, fetchMasterings } from "./masteringApis";
import { useHook } from "app/hooks/common";
import { DirectusImage } from "app/core/common";
import { CiCircleCheck } from "react-icons/ci";
import { IoMdAdd  } from "react-icons/io";
import MasteringPlayer from "./AudioPlayer";
import AudioDuration from "./AudioDuration";
export default function Mastering() {

const { cookie } = useHook();

  const refCover = useRef(null);
  const refSong = useRef(null);
  // keep record of the tracks that has been uploaded
  const [tracks, setTracks] = useState([]);
  // controll the opening and close of the modal window
  const [newProject, setNewProject] = useState(false);
   // save the music desiredLoudness options
   const [masteringsToUpdate, setMasteringsToUpdate] = useState([]);
  // save the music genres options
  const [genres, setGenres] = useState([
    { id: 1, name: 'ROCK_INDIE' },
    { id: 2, name: 'POP' },
    { id: 3, name: 'ACOUSTIC' },
    { id: 4, name: 'HIPHOP_GRIME' },
    { id: 5, name: 'ELECTRONIC' },
    { id: 6, name: 'REGGAE_DUB' },
    { id: 7, name: 'METAL' },
    { id: 8, name: 'OTHER' }
  ]);
  // save the slected genre
  const [genre, setGenre] = useState('');
  // save the slected song file
  const [songFile, setSongFile] = useState(null);
  // save error message
  const [error, setError] = useState(null);
  // save the song image
  const [songImage, setSongImage] = useState(null);
  // save the project name
  const [projectName, setProjectName] = useState("");
  // save the state to open or close mastering modal
  const [openMasterModal, setOpenMasterModal] = useState(false);
  // save the selection of mastgering level
  const [masteringLevel, setMasteringLevel] = useState("");
  // detect when mastering process is running
  const [mastering, setMastering] = useState(false);
  // detect the porcentage of the master process
  const [supportUploadProgress, setSupportUploadProgress] = useState(0);
  // save the song seleected for mastering
  const [masteringSong, setMasteringSong] = useState(null);
  // save the song remastered
  const [remasteredSong, setRemasteredSong] = useState(null);
  const [collapse,setCollapse] = useState(null)
  /* =============================== */
  /* Get genres && list of Mastering*/
  /* ============ Start =========== */

  const fetchMasteringData = async () => {
    const data = await fetchMasterings(cookie);
    setTracks(data);
    const needToUpdate = data.filter(mastering => (mastering.status === 'MASTERING_TASK_IN_PROGRESS'));
    setMasteringsToUpdate(needToUpdate);
  };

  useEffect(() => {
    fetchMasteringData();
  }, []);
  
  useEffect(() => {
    const updateStatus = async () => {
      const updatedMasterings = [];
      for (const mastering of masteringsToUpdate) {
        const data = await PullingSingleMastering(cookie, mastering.id);
        const trackIndex = tracks.findIndex(track => track.id === mastering.id);
        if (data.status !== 'MASTERING_TASK_IN_PROGRESS') {
          updatedMasterings.push(mastering);
        }
        if (trackIndex !== -1) {
          setTracks(prev => {
            const updatedTracks = [...prev];
            updatedTracks[trackIndex] = {
              ...updatedTracks[trackIndex],
              status: data.status,
              mastering_url: data.mastering_url
            };
            return updatedTracks;
          });
        }
      }
      if (updatedMasterings.length > 0) {
        setMasteringsToUpdate(prev => prev.filter(m => !updatedMasterings.find(u => u.id === m.id))); 
      }
    };
  
    const intervalId = setInterval(updateStatus, 15000);
    if (masteringsToUpdate.length === 0) {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [masteringsToUpdate]);
  
  
  /* =============================== */
  /* Pulling Mastering Status & update*/
  /* ============ end ============= */

  // function to store the value of the selected genre
  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    setGenre(value);
  };

  // function to store the music file
  const handleMusicFile = (e) => {
    let file = e.target.files[0];
    if (file.type !== "audio/mp3" && file.type !== "audio/mpeg") {
      return;
    } else if (file.size > 1024 * 1024 * 25) {
      return;
    }
   
    setSongFile(file);
  };

  // function to store song image
  const handleNFtImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files[0];
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/webp" &&
      file.type !== "image/gif"
    ) {
      setError(`The format of the file is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 5) {
      setError("The size of the file is to large");
      return;
    }
    e.target.value = null;
    // const reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = (event) => {
    //   setSongImage(event.target.result);
    // };
      setSongImage(file);
  };

  /* ====================== */
  /* Create the track  */
  /* ======== Start ======= */
  const handleCreateProject =  async() => {
    if (!projectName || !genre || !songFile || !songImage) {
      setError("Please fill in all fields.");
      return;
    }

    // Create a new project object
    const newProject = {
      name: projectName,
      genre,
      masteringLevel,
      songFile,
      songImage,
    };

    const response = await createMastringProject(cookie,newProject)
    
    // Update tracks state by adding the new project object
    await fetchMasteringData()

    // Reset form fields and errors
    setProjectName("");
    setGenre(null);
    setSongFile(null);
    setSongImage(null);
    setError(null);

    // Close the modal
    setNewProject(false);
  };
  /* ====================== */
  /* Create the track */
  /* ======== End ======= */

  /* ====================== */
  /* start the mastering process */
  /* ======== Start ======= */
  const handleMasteringTrack = async () => {
    try {
      setMastering((prev) => !prev);

      /* ====================== */
      /* send the song and level to be transfomed */
      /* ======== Start ======= */

      const mastering = await createMasteringTask(cookie,masteringSong.id, masteringLevel);
       await fetchMasteringData()
      /* ====================== */
      /* send the song and level to be transfomed */
      /* ======== End======= */

      //change the value of masteringLevel with the one that needs to be used to detect the succes off mastering
      if (masteringLevel) {
        // Set initial progress to 0
        setSupportUploadProgress(0);

        // Increment progress gradually using setTimeout
        const incrementProgress = () => {
          // Maximum number of steps for animation
          const maxSteps = 100;
          // Duration for animation in milliseconds
          const duration = 3000; // Adjust duration as needed
          // Interval for each step
          const interval = duration / maxSteps;

          for (let currentStep = 1; currentStep <= maxSteps; currentStep++) {
            setTimeout(() => {
              setSupportUploadProgress(currentStep);
              if (currentStep === maxSteps) {
              }
            }, currentStep * interval);
          }
        };
        setRemasteredSong(masteringSong);
        incrementProgress();
        setTimeout(() => {
          setOpenMasterModal(false);
          setMasteringLevel("");
          setMastering(false);
        }, 3500);

        setSupportUploadProgress(0);
      } else {
        setSupportUploadProgress(0);
        setOpenMasterModal(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  /* ====================== */
  /* start the mastering process */
  /* ======== End ======= */

  return (
    <div className={styles.mastering_container}>
      <div className={styles.mastering_title}>
        <h1>Mastering</h1>
        {tracks?.length > 0 ? (
            <IoMdAdd className={styles.addBtn}  size={30} onClick={() => setNewProject((prev) => !prev)}/>     
          // <Button size="sm" onClick={() => setNewProject((prev) => !prev)}>
          // </Button>
        ) : null}
      </div>
      <div className={styles.mastering_subtitle}>
        Master your tracks in a single click using the power of AI to produce
        high fidelity music
      </div>
      <div className={styles.mastering_container}>
        <div className={styles.song_list_container}>
          <div className={styles.song_list_infos}>
            <span>COVER</span>
            <span>PROJECT</span>
          </div>
          <div className={styles.song_list_infos}>
            <span>GENRE</span>
            <span>DURATION</span>
          </div>
        </div>

        <div className={styles.line}></div>
        <div className={styles.mastering_tracks}>
          {tracks && tracks.length > 0 ? (
            <>
              {tracks.map((track, index) => (
                <div className={styles.track_container} key={index}>
                  <div className={`${styles.track} ${collapse === track.id && styles.trackActive}`} onClick={()=>setCollapse(track.id)}>
                    <div className={styles.first_part}>
                      <div className={styles.track_position_image}>
                        <span>{index + 1}</span>
                        {/* Displaying the track number */}
                        <img src={DirectusImage(track?.projectBannerImage,60) || ""} alt="loop_track" />
                      </div>
                      <span className={styles.song_name}>
                        {track?.name ? truncate(track?.name, 14, "..."): "No Name"}
                      </span>
                      {/* Displaying the track name */}
                    </div>
                    <div className={styles.second_part}>
                      <span>{track?.musicalStyle}</span>
                      {/* <span>{track?.genre}</span> */}
                    </div>
                    {
                      track.mastering_url &&
                    <div className={styles.second_part}>
                      {
                        <AudioDuration audioUrl={track.mastering_url} />
                      }
                    </div>
                    }

                    <div className={styles.track_button}>
                      {track.status === 'PROJECT_CREATED' &&
                      <Button
                        size="sm"
                        onClick={() => {
                          setOpenMasterModal((prev) => !prev);
                          setMasteringSong(track);
                        }}
                        
                      >
                        Master 
                        {/* <div className="loader"></div> */}
                      </Button>
                        
                      }
                      {track.status === 'MASTERING_TASK_IN_PROGRESS' &&
                      <Button
                        size="sm" disabled 
                      >
                        MASTERING IN PROGRESS
                        {/* <div className="loader"></div> */}
                      </Button>
                        
                      }
                      {track.status === 'MASTERING_TASK_COMPLETED' &&
                       <Button
                        size="sm"
                        onClick={() => { 
                          setCollapse(track.id);
                          console.log("collapse",collapse)
                        }}
                      >
                        <span> <CiCircleCheck className={styles.cheakedSign}/></span> 
                        Master 
                        {/* <div className="loader"></div> */}
                      </Button>
                            }
                    </div>
                    {
                      track.mastering_url !==  null &&
                    <div className={styles.track_button}>
                    <a className={styles.track_button} href={track.mastering_url} download={`${track.name}mastered.wav`}>
                      <SaveAltIcon />
                      <span>Download</span>
                      </a>
                    </div>
                    }
                    {/* <div className={styles.track_button}>
                    <a className={styles.track_button} href={track.orginalTrackUrl} download={`${track.name}orignal.wav`}>
                      <SaveAltIcon />
                      <span>Download Orignal</span>
                      </a>
                    </div> */}
                  </div>
                  {collapse !== null  && collapse === track.id && track.mastering_url && <div className={collapse === track.id ? styles.collapseActive : styles.collapse}> 
                   <MasteringPlayer song={track}/>
                  </div>
                  }
                  {/* {remasteredSong?.name === track?.name ? (
                    <div className={styles.track}>
                      <div className={styles.track_position_image}>
                        <span></span>
                        Displaying the track number 
                        <div></div>
                      </div>
                      <span>
                        {truncate(remasteredSong?.name, 14, "...")}(mastered)
                      </span>{" "}
                       Displaying the track name 
                      <span></span>
                      <span></span>
                      <div className={styles.track_button}>
                        <div></div>
                      </div>
                      <div className={styles.track_button}>
                        <SaveAltIcon />
                        <span>Download</span>
                      </div>
                    </div>
                  ) : null} */}
                </div>
              ))}
            </>
          ) : (
            <div className={styles.no_tracks_container}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <MusicNoteIcon fontSize="large" />
                <span>
                  You dont have any projects set up yet
                  <br />
                  Create a new project to start mastering
                </span>
              </div>
              <Button size="sm" onClick={() => setNewProject((prev) => !prev)}>
                + New Project
              </Button>
            </div>
          )}
        </div>
      </div>
      {/* Modal to upload a track Start*/}
      <Modal
        onClose={() => setNewProject(false)}
        isOpen={newProject}
        title={"New Project"}
        customSubClass="defaultModal"
      >
        <div className="defaultModalContent">
          <div className={styles.option}>
            <span>Project Name</span>
            <div className={styles.input}>
              <input
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.option}>
            <span>Genre</span>
            <div className={styles.input}>
              <select
                required
                onChange={handleRegisterChange}
                defaultValue={genre ? genre : "Select Genre"}
                style={{ marginBottom: "10px" }}
              >
                {genres &&
                  genres?.map((gen) => (
                    <option key={gen.id} value={gen.name}>
                      {gen.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className={styles.option}>
            <span>Upload your track</span>
            <div
              className={styles.input_song}
              onClick={() => {
                refSong.current.click();
              }}
            >
              <LibraryMusicIcon sx={{ color: blue[700] }} />
              <input
                type="file"
                ref={refSong}
                hidden
                onChange={(e)=>handleMusicFile(e)}
                accept="audio/mp3"
              />
              <span className={styles.input_song_title}>
                {songFile ? songFile.name : "No file selected"}
                <small> (Max: 25MB MP3)</small>
              </span>
            </div>
          </div>
          <div className={styles.option}>
            <span>Cover Image</span>
            <div
              className={styles.input_song}
              onClick={() => {
                refCover.current.click();
              }}
            >
              <ImageIcon sx={{ color: blue[700] }} />
              <input
                type="file"
                ref={refCover}
                hidden
                onChange={(e) => handleNFtImage(e)}
                accept="image/jpeg,image/png,image/webp,image/jfif,image/gif"
              />
              {songImage ? (
                <span>Image selected</span>
              ) : (
                <span>No image selected</span>
              )}
              <span>796 x 1058px</span>
            </div>
          </div>

          <div className="defaultModalButtons">
            <Button size="sm" onClick={handleCreateProject}>
              Create Project
            </Button>
          </div>
        </div>
      </Modal>
      {/* Modal to upload a track END */}
      {/* Modal to mastering the uploaded song Start*/}
      <Modal
        onClose={() => setOpenMasterModal(false)}
        isOpen={openMasterModal}
        title={"Master"}
        customSubClass="defaultModal"
      >
        {mastering ? (
          <div className="defaultModalContent">
            <div className={styles.mastering_progress}>
              <div className={styles.progress_bar}>
                <progress
                  className={styles.progresss}
                  value={supportUploadProgress}
                  max="100"
                />
                <span>Mastering in progress...</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="defaultModalContent">
            <span>Choose the loudness of your master</span>
            <div
              className={
                masteringLevel === "LOW"
                  ? styles.mastering_option_active
                  : styles.mastering_option
              }
              onClick={() => setMasteringLevel("LOW")}
            >
              Low <InfoIcon fontSize="small" style={{ cursor: "pointer" }} />
            </div>
            <div
              className={
                masteringLevel === "MEDIUM"
                  ? styles.mastering_option_active
                  : styles.mastering_option
              }
              onClick={() => setMasteringLevel("MEDIUM")}
            >
              Medium <InfoIcon fontSize="small" style={{ cursor: "pointer" }} />
            </div>
            <div
              className={
                masteringLevel === "HIGH"
                  ? styles.mastering_option_active
                  : styles.mastering_option
              }
              onClick={() => setMasteringLevel("HIGH")}
            >
              High <InfoIcon fontSize="small" style={{ cursor: "pointer" }} />
            </div>

            <div className="defaultModalButtons">
              <Button
                size="sm"
                onClick={handleMasteringTrack}
                disabled={!masteringLevel}
              >
                Master Project
              </Button>
            </div>
          </div>
        )}
      </Modal>
      {/* Modal to mastering the uploaded song END*/}
    </div>
  );
}
