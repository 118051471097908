import React, { useEffect, useRef, useState } from "react";
import styles from "./Create.module.scss";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { blue } from "@mui/material/colors";
import FadeLoader from "react-spinners/FadeLoader";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import axios from "axios";
import OnBoardNft from "app/components/onboard/onboard_nft_image/OnBoardNft";
import images from "./images.json";
import { useHook } from "app/hooks/common";
import { useHistory } from "react-router-dom";
import { DirectusImage, ProfileData, isCreator } from "app/core/common";
import * as api from "app/api/profile.api";
import {
  convertElementToSvgFile,
  createVoteNFT,
  setGenreAndDivision,
} from "app/helpers/CreateNft";
import Button from "app/components/button/button";
import Loader from "app/components/Loader";

export default function Create() {
  const [songFile, setSongFile] = useState(null);
  const [nftImage, setNftImage] = useState(null);
  const [nftBackGround, setNftBackGround] = useState(null);
  const [genre, setGenre] = useState(null);
  const [division, setDivision] = useState(null);
  const refCover = useRef(null);
  const refSong = useRef(null);
  const elementRef = useRef(null); // Reference to the content div
  const [error, setError] = useState(null);
  const [converting, setConverting] = useState(false);
  const [genres, setGenres] = useState(null);
  const [divisions, setDivisions] = useState(null);
  const { userInfo, cookie, updateUserInfo } = useHook();
  const history = useHistory();
  const userId = userInfo?.id;
  const [profileData, setProfileData] = useState<ProfileData>(null);
  const [imageProfileFinal, setImageProfileFinal] = useState(null);
  const [imageProfile, setImageProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);

  const imageBottomRigth =
    "https://loop-markets.directus.app/assets/ce36c33e-2a96-4543-9a95-ff791dc70a21";
  const imageBottomLeft =
    "https://loop-markets.directus.app/assets/9fd7b350-b91a-48ff-b493-659351368603";
  const imageTop =
    "https://loop-markets.directus.app/assets/27e236b8-0989-42a1-90b8-774f33839f54";

  const [infos, setInfos] = useState({
    username: "",
    display_name: "",
  });

  /* ====================== */
  /* Get User information to fill nft info */
  /* ======== Start ======= */
  useEffect(() => {
    const fetchData = async () => {
      try {
        await api.GetProfile({ id: userId }).then((user) => {
          setProfileData(user[0]);

          // Set the initial values for the profile and cover pictures
          if (user[0]?.avatar?.id) {
            setImageProfileFinal(DirectusImage(user[0]?.avatar?.id));
            setImageProfile(DirectusImage(user[0]?.avatar?.id));
          }
          if (user[0]?.display_name) {
            setInfos((prevInfos) => ({
              ...prevInfos,
              display_name: user[0]?.display_name,
            }));
          }
          if (user[0]?.username) {
            setInfos((prevInfos) => ({
              ...prevInfos,
              username: user[0]?.username,
            }));
          }
        });
        return updateUserInfo();
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [userId]);
  /* ====================== */
  /*  Get User information to fill nft info */
  /* ======== End ======= */

  /* ====================== */
  /* Get division and genres of the NFT */
  /* ======== Start ======= */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [response, responseGeners] = await setGenreAndDivision();
        setDivisions(response?.data?.divisions);
        setGenres(responseGeners?.data?.genres);
      } catch (error) {}
    };

    fetchData();
  }, []);
  /* ====================== */
  /*  Get division and genres of the NFT */
  /* ======== End ======= */

  //set the background of the NFT with the selected image
  const handleNftBackGround = (nftImage) => {
    setNftBackGround(nftImage);
  };

  // check the selected music file for type and size
  const handleMusicFile = (e) => {
    let file = e.target.files[0];
    if (file.type !== "audio/mp3" && file.type !== "audio/mpeg") {
      alert(`The format of the file is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 25) {
      alert("The size of the file is to large");
      return;
    }
    setSongFile(file);
  };

  // when selected the image from the input, set the background of the preview NFT with it
  const handleNFtImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files[0];
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/webp" &&
      file.type !== "image/gif"
    ) {
      setError(`The format of the file is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 5) {
      setError("The size of the file is to large");
      return;
    }
    e.target.value = null;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setNftBackGround(event.target.result);
    };
  };

  // convert base 64 to svg
  const htmlToImageConvert = async () => {
    try {
      const file = await convertElementToSvgFile(elementRef);
      setNftImage(file);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (nftBackGround) {
      htmlToImageConvert();
    }
  }, [nftBackGround]);

  // store the selected value for genre and division
  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    if (name === "genre") {
      setGenre(value);
    } else {
      setDivision(value);
    }
  };

  /* ====================== */
  /*  handleCreateVoteNFT "controll the creaton of the vote NFT" */
  /* ======== Start ======= */
  useEffect(() => {
    if (genre && division && nftImage && songFile) {
      setReady((prev) => !prev);
    }
  }, [genre, division, nftImage, cookie, songFile]);

  /* ====================== */
  /*  handleCreateVoteNFT "controll the creaton of the vote NFT" */
  /* ======== Start ======= */
  const handleCreateVoteNFT = async (status) => {
    try {
      setLoading((prev) => !prev);
      const response = await createVoteNFT(
        genre,
        division,
        nftImage,
        cookie,
        songFile,
        status
      );

      if (response?.status === 200) {
        // Continue to next step
        setGenre("");
        setDivision("");
        setNftImage(null);
        setSongFile(null);
        setNftBackGround(null);
        setLoading((prev) => !prev);

        if (status === "draft") {
          history.push(`/inDraft/`);
        } else {
          history.push(`/liveCollections/`);
        }
      }
      if (response?.status !== 200) {
        // Continue to next step

        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  /* ====================== */
  /*  handleCreateVoteNFT "controll the creaton of the vote NFT" */
  /* ======== End ======= */

  return (
    <div className={styles.step_five_all}>
      <div className={styles.step_five_category}>
        <h1>Voting Category</h1>
        <div className={styles.categories_select}>
          <div className={styles.select1}>
            <div className={styles.upload_music_button}>
              {/* <h1>Upload a song</h1> */}
              <span>
                Which song would you like to enter into the awards?{" "}
                <small> (Max: 25MB MP3)</small>
              </span>
              <div
                className={styles.image_icon}
                onClick={() => {
                  refSong.current.click();
                }}
              >
                <LibraryMusicIcon sx={{ color: blue[700] }} />
                <input
                  type="file"
                  ref={refSong}
                  hidden
                  onChange={handleMusicFile}
                  accept="audio/mp3"
                />
                <span className={styles.input_song_title}>
                  {songFile ? songFile.name : "No file selected"}
                  <small> (Max: 25MB MP3)</small>
                </span>
              </div>
            </div>
          </div>
          <div className={styles.select2}>
            <>
              <label htmlFor="genre">
                Which genre would you like to compete in?
              </label>
              <select
                name="genre"
                id="genre"
                required
                onChange={handleRegisterChange}
                value={genre}
                style={{ marginBottom: "10px" }}
              >
                {genres &&
                  genres?.map((gen) => (
                    <option key={gen.id} value={gen.id}>
                      {gen.name}
                    </option>
                  ))}
              </select>
            </>

            <>
              <label htmlFor="division">Which division are you in?</label>
              <select
                name="division"
                id="division"
                required
                onChange={handleRegisterChange}
                value={division}
              >
                {divisions &&
                  divisions?.map((option) => (
                    <option key={option.id} value={option.id}>
                      <div>
                        {option.description
                          ? `${option.name} - (${option.description})`
                          : option.name}
                      </div>
                    </option>
                  ))}
              </select>
            </>
          </div>
        </div>
        <hr />
      </div>
      <div className={styles.step_five}>
        <div className={styles.step_five_left}>
          <h1>Create Vote NFT</h1>
          <div>When your fans vote for you they will receive a free NFT</div>

          {/* <div className={styles.upload_music_button}>
        <h1>Upload a song</h1>
        <span>Which song would you like to enter into the awards?</span>
        <div
          className={styles.image_icon}
          onClick={() => {
            refSong.current.click();
          }}
        >
          <LibraryMusicIcon sx={{ color: blue[700] }} />
          <input
            type="file"
            ref={refSong}
            hidden
            onChange={handleMusicFile}
            accept="audio/mp3"
          />
          <span className={styles.input_song_title}>
            {songFile ? songFile.name : "No file selected"}
          </span>
        </div>
      </div> */}
          <div className={styles.border}></div>
          <div className={styles.step_five_left_content}>
            <div className={styles.step_five_left_content_title}>
              <h1 className={styles.step_five_left_content_title_span}>
                NFT Artwork
              </h1>
              <span>Select from a template</span>
            </div>
            <div className={styles.step_five_image_sample}>
              <div
                className={styles.step_five_image_sample_square}
                onClick={() => handleNftBackGround(images[0]?.url)}
              >
                <img
                  src="https://loop-markets.directus.app/assets/315d3685-d60c-415f-841f-77b97fde6593"
                  alt=""
                  className={styles.step_five_image_sample_square_image}
                />
              </div>
              <div className={styles.step_five_image_sample_three}>
                {images.slice(1).map((image, index) => (
                  <div
                    className={styles.other_images}
                    key={image.id}
                    onClick={() => handleNftBackGround(image.url)}
                  >
                    <img src={image.url} alt="" />
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.upload_option}>
              <span>Or upload your own</span>
              <div
                className={styles.image_icon}
                onClick={() => {
                  refCover.current.click();
                }}
              >
                <AddPhotoAlternateIcon sx={{ color: blue[700] }} />
                <input
                  type="file"
                  ref={refCover}
                  hidden
                  onChange={(e) => handleNFtImage(e)}
                  accept="image/jpeg,image/png,image/webp,image/jfif,image/gif"
                />
                <span>796 x 1058px</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.step_five_right}>
          <h1>Preview</h1>
          {userInfo ? (
            <OnBoardNft
              elementRef={elementRef}
              nftBackGround={nftBackGround}
              imageTop={imageTop}
              imageProfile={imageProfile}
              infos={infos}
              imageBottomLeft={imageBottomLeft}
              imageBottomRigth={imageBottomRigth}
              normal
            />
          ) : (
            <div className={styles.before_info}>
              <div
                style={{
                  position: "relative",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
              >
                <Loader />
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Button
              className="btn_md"
              disabled={!ready}
              onClick={() => handleCreateVoteNFT("published")}
              loading={loading}
            >
              Create Vote NFT
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
