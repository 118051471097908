import React from 'react';
import styles from './PaymentModule.module.scss';
import banner from "../../../../assets/creditBanner.png";
import PriceCard from './PriceCard';

const PaymentModule = () => {
  return (
    <div className={styles.paymentModule}>
      <header className={styles.header}>
        <h1>Buy Mastering Credits</h1>
        <div className={styles.banner}>
        <img src={banner}/>
        </div>
      </header>
      <div className={styles.cardsContainer}>
       <PriceCard/>
      </div>
    </div>
  );
};

export default PaymentModule;
