import HowToVoteIcon from "@mui/icons-material/HowToVote";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import SellIcon from "@mui/icons-material/Sell";
import SavingsIcon from "@mui/icons-material/Savings";
import DrawIcon from "@mui/icons-material/Draw";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import AttachFileIcon from "@mui/icons-material/AttachFile";

export const benefits = [
  {
    id: 1,
    benefit: "Music",
    available: true,
    icon: <MusicNoteIcon fontSize="small" />,
  },
  {
    id: 2,
    benefit: "Video",
    available: true,
    icon: <OndemandVideoIcon fontSize="small" />,
  },
  {
    id: 3,
    benefit: "File",
    available: true,
    icon: <AttachFileIcon fontSize="small" />,
  },
];
