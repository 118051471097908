import React from 'react';
import styles from './PaymentModule.module.scss';
import { FaRegCheckCircle } from "react-icons/fa";
const PriceCard = () => {
  const packages = [
    { id: 1, name:"Single Master", offer: 1, topPick: false, items: ['1 Download Credits', '16 Bit & 44.1 kHz sample rate', 'MP3 (320kbps), FLAC, WAV'], currentPayment: 2.49, originalPayment: 4.99, colorClass: styles.greyGradient },
    { id: 2, name:"Max Master", offer: 5, topPick: false, items: ['5 Download Credits', '16 Bit & 44.1 kHz sample rate', 'MP3 (320kbps), FLAC, WAV'], currentPayment: 9.98, originalPayment: 19.99,  colorClass: styles.lightBlueGradient },
    { id: 3,  name:"Pro Master", offer: 20 ,topPick: true, items: ['20 Download Credits', '16 Bit & 44.1 kHz sample rate', 'MP3 (320kbps), FLAC, WAV'], currentPayment: 29.90, originalPayment: 59.80,  colorClass: styles.purpleGradient },
    { id: 4,  name:"Mega Master", offer: 10 ,topPick: false, items: ['10 Download Credits', '16 Bit & 44.1 kHz sample rate', 'MP3 (320kbps), FLAC, WAV'], currentPayment: 17.45, originalPayment: 34.95, colorClass: styles.darkBlueGradient},
  ];

  return (
      <>
        {packages.map(card => (
          <div key={card.id} className={`${styles.card}  ${card.topPick ? styles.topPick : " "}`}>
            <div className={`${styles.paymentHeader} ${card.colorClass}`}>
              <span className={styles.pkgHeading}>{card.name}</span>
              <span className={styles.pkgOffer}>{card.offer} PACK</span>
              {card.topPick && <div className={styles.TopPicked}>
                TOP PICK
              </div>}
            </div>
            <div className={`${styles.price}`}>
              <span className={styles.currentPayment}>${card.currentPayment}</span>
              <span className={styles.perCredit}>{`$${(card.currentPayment / card.offer).toFixed(2)} / PER CREDIT`}</span>
              <span className={styles.originalPayment}>${card.originalPayment}</span>
              <span className={styles.strock}></span>
            </div>
            <ul className={`${styles.cardItems} ${card.topPick ? styles.ItemPickSpace : " "}`}>
              {card.items.map((item, index) => (
                <li key={index}><FaRegCheckCircle />{item}</li>
              ))}
            </ul>
            <button className={`${styles.cardButton} ${card.colorClass}`}>BUY</button>
          </div>
        ))}
      </>
  );
};

export default PriceCard;
