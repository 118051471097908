import React, { useCallback, useEffect, useState } from "react";
import styles from "../CreateCollection/CreateCollection.module.scss";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import JSZip from "jszip";
import Button from "app/components/button/button";
import { addFileBenefit, editFileBenefit } from "app/helpers/FileBenefit";
import { useHook } from "app/hooks/common";

export default function AddFileStructure({
  setFileName,
  fileName,
  setFile,
  file,
  fansCollectionId,
  cookie,
  fileId,
  setCollectionBenefits,
  setFetchTrigger,
  setAddFile,
  addFile,
  editFile,
  setEditFile,
}: {
  setFileName: any;
  fileName: string;
  file: any;
  setFile: (value: any) => void;
  fansCollectionId: string;
  cookie: string;
  fileId: string;
  setCollectionBenefits: (value: any) => void;
  setFetchTrigger: (value: any) => void;
  setAddFile: (value: any) => void;
  addFile: boolean;
  editFile: boolean;
  setEditFile: (value: any) => void;
}) {
  const [zipContents, setZipContents] = useState<string[]>([]);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const TrackSizeValidator = (file: File) => {
    const maxFileSize = 25 * 1024 * 1024; // 25MB

    if (file.size > maxFileSize) {
      return {
        code: "name-too-large",
        message: `File size exceeds the maximum allowed size of 25MB.`,
      };
    }
    return null;
  };

  useEffect(() => {
    setReady(!!fileName && file?.length > 0);
  }, [file, fileName]);

  useEffect(() => {
    // Clean up the URL.createObjectURL
    return () => {
      file?.forEach((file) => URL.revokeObjectURL(file?.preview));
    };
  }, [file]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFile = acceptedFiles?.map((file) => ({
        file,
      }));
      setFile(newFile); // Since only one video is allowed, we overwrite the state

      const zipFile = acceptedFiles[0];
      if (zipFile?.type === "application/zip") {
        const zip = new JSZip();
        zip.loadAsync(zipFile).then((zipContent) => {
          const fileNames = [];
          zipContent?.forEach((relativePath, zipEntry) => {
            fileNames?.push(zipEntry.name);
          });
          setZipContents(fileNames);
        });
      } else {
        setZipContents([]);
      }
    },
    [setFile]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept: {
        "application/zip": [".zip"],
      },
      maxFiles: 1,
      validator: TrackSizeValidator,
    });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name}>
      {file?.name} - {file?.size} bytes
      <ul>
        {errors?.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  /* ====================== */
  /* upload file of the Collection */
  /* ======== Start ======= */
  const createCollectionVideo = async () => {
    try {
      setLoading(true);

      const response = await addFileBenefit(
        fansCollectionId,
        cookie,
        fileName,
        file
      );

      if (response) {
        setCollectionBenefits([]);
        setAddFile(false);
        setFetchTrigger((prev) => !prev);
      } else {
        // Handle error
        alert("Failed to upload video");
      }
    } catch (error) {
      console.error("Error creating album:", error);
      alert("An error occurred while creating the album");
    } finally {
      setLoading(false);
    }
  };
  /* ====================== */
  /* upload file of the Collection  */
  /* ======== End ======= */

  /* ====================== */
  /* edit file of the Collection */
  /* ======== Start ======= */

  const handleEdit = async () => {
    try {
      setLoading(true);
      const response = await editFileBenefit(
        fansCollectionId,
        cookie,
        fileName,
        fileId
      );
      if (response) {
        setFetchTrigger((prev) => !prev);
        setEditFile(false);
      } else {
        // Handle error
        alert("Failed to upload video");
      }
    } catch (error) {
      console.error("Error creating album:", error);
      alert("An error occurred while creating the album");
    } finally {
      setLoading(false);
    }
  };

  /* ====================== */
  /* edit file of the Collection  */
  /* ======== End ======= */

  return (
    <div className={styles.album_infos}>
      <div className={styles.inputs_album}>
        <div className={styles.input_label}>
          <span>File Name</span>
          <div className={styles.input_song}>
            <input
              type="text"
              value={fileName}
              onChange={(e) => setFileName(e?.target?.value)}
            />
          </div>
        </div>
      </div>

      {file?.length > 0 ? (
        <div className={styles.video_preview}>
          {editFile ? null : (
            <div className={styles.close_icon} onClick={() => setFile([])}>
              <CloseIcon />
            </div>
          )}
          {zipContents.length > 0 && (
            <div className={styles.zip_contents}>
              <h3>Zip File Contents:</h3>
              <ul className={styles.file_list}>
                {zipContents.map((fileName, index) => (
                  <li key={index}>{fileName}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.drag_drop_contianer}>
          <div {...getRootProps()} className={styles.drag_drop}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <div>
                <p>Drag 'n' drop your file, or click to select file</p>
                <em>(1 file Max - 25MB max size)</em>
              </div>
            )}
          </div>
          {fileRejectionItems.length > 0 && (
            <div className={styles.file_rejections}>
              <ul>{fileRejectionItems}</ul>
            </div>
          )}
        </div>
      )}
      <div className="defaultModalButtons">
        <Button
          size="sm"
          onClick={
            addFile ? createCollectionVideo : editFile ? handleEdit : null
          }
          disabled={loading ? loading : !ready}
          loading={loading}
        >
          Done
        </Button>
      </div>
    </div>
  );
}
