import HowToVoteIcon from "@mui/icons-material/HowToVote";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import SellIcon from "@mui/icons-material/Sell";
import SavingsIcon from "@mui/icons-material/Savings";
import DrawIcon from "@mui/icons-material/Draw";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import { PiVinylRecord } from "react-icons/pi";

export const options = [
  {
    title: "Music Collection",
    message:
      "Sell your tracks directly to fans, maximize revenue and engage your fanbase directly",
    to: "/createCollection",
    available: true,
    icon: <MusicNoteIcon fontSize="large" />,
  },

  {
    title: "Smart Vinyl",
    message:
      "Smart Vinyl digital collectibles, connect digital experiences to physical vinyl records",
    available: true,
    icon: <PiVinylRecord fontSize={40} />,
    link: "https://smartvinyl.io/",
  },
  {
    title: "Video NFT",
    message:
      "Generate hype on your music video pre-release with a scene of your videoclip",
    available: false,
    icon: <OndemandVideoIcon fontSize="large" />,
  },

  {
    title: "Fans Membership",
    message: "Set up memberships that grants access to your exclusive feed",
    available: false,
    icon: <CardMembershipIcon fontSize="large" />,
  },
  {
    title: "Exclusive content",
    message: "Grant access to fans to your exclusive content",
    available: false,
    icon: <WorkspacePremiumIcon fontSize="large" />,
  },

  {
    title: "Merch Sales",
    message:
      "Sell a digital copy of your Merch that is redeemable for its physical version",
    available: false,
    icon: <SellIcon fontSize="large" />,
  },
  {
    title: "Voting Collection",
    message: "Participate in more categories of the WMAs competition",
    //to: "/createNFT",
    available: false,
    icon: <HowToVoteIcon fontSize="large" />,
  },
];
