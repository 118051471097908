import Cookie from "js-cookie";
import { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import { toUtf8 } from "@cosmjs/encoding";
import { DirectSecp256k1Wallet } from "@cosmjs/proto-signing";
import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { REACT_APP_SOCKET_URL, REACT_APP_RPC } from "../constants";

const modifiedURL = process.env.REACT_APP_COOKIE_DOMAIN;

const socket = io(REACT_APP_SOCKET_URL, {
  transports: ["websocket"],
  withCredentials: true,
});

let cachedClient;
let cachedWallet;

const getClient = async (key = "") => {
  if (key.length > 0) {
    if (!cachedClient && !cachedWallet) {
      try {
        const privateKey = toUtf8(key);
        const wallet = await DirectSecp256k1Wallet.fromKey(privateKey, "juno");
        const client = await SigningCosmWasmClient.connectWithSigner(
          REACT_APP_RPC,
          wallet
        );

        cachedClient = client;
        cachedWallet = wallet;

        return {
          wallet,
          client,
        };
      } catch (e) {
        console.log("wallet connection failed", e);
        return undefined;
      }
    } else {
      //console.log("Using cached wallet");
      return {
        wallet: cachedWallet,
        client: cachedClient,
      };
    }
  }
  return undefined;
};

const useConnectWallet = () => {
  const [address, setAddress] = useState();
  const [cookie, setCookie] = useState();
  let [client, setClient] = useState("");
  let [wallet, setWallet] = useState("");
  const count = useRef(0);

  useEffect(() => {
    socket.on("getTokenRes", (msg) => {
      if (msg.success && socket.id === msg.id) {
        getClient(msg.token).then((cli) => {
          setClient(cli.client);
          setWallet(cli.wallet);
        });
      }
    });
    socket.on("error", () => {
      console.log("socket disconnected");
    });
    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);
  const getToken = () => {
    const cookie = Cookie.get("cookie");
    cookie && setCookie(cookie);
    cookie &&
      socket.id &&
      socket.emit("getToken", { cookie: cookie, id: socket.id });
  };
  const initialize = () => {
    getToken();
    const address = Cookie.get("address");
    address && setAddress(address);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!client && count.current <= 7) {
        initialize();
        count.current += 1;
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handler = (event) => {
      if (event.data.address) {
        setAddress(event.data.address);
        Cookie.set("cookie", event.data.cookie, {
          sameSite: "none",
          secure: true,
          domain: modifiedURL,
        });
        Cookie.set("address", event.data.address, {
          sameSite: "none",
          secure: true,
          domain: modifiedURL,
        });
        getToken();
      }
    };
    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, []);

  const login = (type) => {
    try {
      const sec = type === "sms" ? "/sms" : "/login/federated/google";
      const url = REACT_APP_SOCKET_URL + sec;
      let newWindow = window.open(url, "name", "height=600,width=600");
      newWindow?.postMessage(JSON.stringify({ obj: window }), "*");
      if (window.focus) {
        newWindow.focus();
      }
      return false;
    } catch (err) {}
  };
  const logout = () => {
    Cookie.set("cookie", "", {
      sameSite: "none",
      secure: true,
      domain: modifiedURL,
    });
    Cookie.set("address", "", {
      sameSite: "none",
      secure: true,
      domain: modifiedURL,
    });
    setAddress(undefined);
  };

  return { logout, cookie, address, client, login, wallet };
};
export default useConnectWallet;
