import { useLogin } from "app/context/loginProvider";
import { useHook } from "app/hooks/common";
import LoginPage from "app/pages/Login/Login";
import { Route, Redirect } from "react-router-dom";

const AuthenticatedRoute = ({
  component: Component,
  containerRef,
  ...rest
}) => {
  const { address } = useHook();

  const { showLoginModal, showSignup, toggleLoginModal, toggleSignupModal } =
    useLogin();

  return (
    <Route
      {...rest}
      render={(props) =>
        address ? (
          <Component {...props} containerRef={containerRef} />
        ) : (
          (toggleLoginModal(),
          toggleSignupModal(true),
          (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          ))
        )
      }
    />
  );
};

export default AuthenticatedRoute;
