import axios from "axios";

export const deleteBenefit = async (
  cookie: string,
  benefit: string,
  benefitId: string
) => {
  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_MIDDLEWARE}benefit/delete`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { cookie: cookie, benefit_type: benefit, benefit_id: benefitId },
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};
