import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./Aitools.module.scss";

export default function Aitools() {
  const history = useHistory();
  const handleSubItems = (to) => {
    history.push(to);
  };

  return (
    <div className={styles.collections_container}>
      <h1>AI Tools</h1>
      <span>
        Access Mastering to create new AI-driven Music and explore AI Video
        Music Creations.
      </span>
      <div className={styles.collections_options}>
        <div
          className={styles.collections_option}
          onClick={() => handleSubItems("/mastering")}
        >
          <h2>Mastering</h2>
          <p>
            Master your tracks in a single click using the power of AI to
            produce high fidelity music.
          </p>
        </div>
        <div
          className={styles.collections_option}
          //   onClick={() => handleSubItems("/inDraft/")}
          style={{ background: "#36373a" }}
        >
          <h2>AI Music Video</h2>
          <p>Create AI Music Videos to express to a next level your music.</p>
        </div>
      </div>
    </div>
  );
}
