import axios from "axios";

export const fetchBalance = async (cookie: string) => {
  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_MIDDLEWARE}user/action/account/balance`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { cookie: cookie },
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const fetchPymentHistory = async (cookie: string) => {
  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_MIDDLEWARE}user/action/payout/history`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { cookie: cookie },
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const requestPayout = async (
  cookie: string,
  amount: string,
  platform: string,
  payout_email: string,
  full_name: string,
  whatsappNumber: string
) => {
  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_MIDDLEWARE}user/action/payout/request`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        cookie: cookie,
        amount: amount,
        platform: platform,
        payout_email: payout_email,
        full_name: full_name,
        whatsapp: whatsappNumber,
      },
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};
