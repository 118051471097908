import React, { useEffect, useState } from "react";
import styles from "./InDraft.module.scss";
import { CreatorCollections, ProfileData } from "app/core/common";
import * as api from "app/api/collections.api";
import { useHook } from "app/hooks/common";
import Loader from "app/components/Loader";
import NftDrops from "app/components/nft_drops_/NftDrops";
import { useHistory } from "react-router-dom";
import { InView, useInView } from "react-intersection-observer";

export default function InDraft() {
  const [collections, setCollections] = useState<CreatorCollections[]>([]);
  const [loading, setLoading] = useState(true);
  const { userInfo, cookie } = useHook();
  const [page, setPage] = useState<number>(1);
  const [more, setMore] = useState(false);
  const limit = 8;
  const history = useHistory();
  const status_name = "draft";
  const { ref } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const handleInViewChange = (inView) => {
    if (inView) {
      showMore();
    }
  };

  const showMore = () => {
    setPage((prev) => prev + 1);
  };

  // fetch nft status draft
  useEffect(() => {
    const fetchCollections = async () => {
      if (page === 1) {
        setLoading(true);
      }
      try {
        const data = await api.getCreatorCollections({
          userId: userInfo?.id,
          status_name,
          cookie,
          page,
          limit,
        });
        if (data?.length >= 8) {
          setMore(true);
        } else {
          setMore(false);
        }
        setCollections((prev) => [...prev, ...data]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (userInfo) {
      fetchCollections();
    }
  }, [userInfo, cookie, page]);

  const handleEdit = (creator) => {
    if (
      /* creator.collection_type === "collectible" ||
      creator.collection_type === "support" */
      creator.collection_type === "vote"
    ) {
      history.push(`/editNftCollection/${creator?.id}`);
    } else {
      history.push(`/editCollection/${creator?.id}`);
    }
  };

  const handleViewInsights = (creator) => {
    history.push(`/collection/${creator?.id}/insights`);
  };

  return (
    <div className={styles.in_draft_container}>
      <div className={styles.in_draft_title}>
        <h1>In Draft</h1>
      </div>
      <div className={styles.in_draft_message}>
        Click on your saved collections to complete the submission
        <br />
        Once submitted they will be ready for launch
      </div>

      <div>
        {loading ? (
          <div
            style={{
              position: "relative",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "rgb(23 25 27 / 48%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        ) : collections?.length > 0 ? (
          <>
            <div className={styles.courses}>
              {collections?.map((collection, index) => (
                <NftDrops
                  creator={collection}
                  key={index}
                  handleEdit={handleEdit}
                  handleViewInsights={handleViewInsights}
                />
              ))}
            </div>
            {more && (
              <InView as="div" onChange={handleInViewChange}>
                <div ref={ref} onClick={() => showMore()}></div>
              </InView>
            )}
          </>
        ) : (
          <h1>No Draft Collections Available</h1>
        )}
      </div>
    </div>
  );
}
