import React, { useState } from "react";
import styles from "./PreviewImage.module.scss";
import { DirectusImage } from "app/core/common";

export default function PreviewImage({
  image,
  collectionName,
  edit,
  vote,
}: {
  edit?: boolean;
  image?: any;
  collectionName?: string;
  vote?: boolean;
}) {
  const isBase64Image = (image: any) => {
    return typeof image === "string" && image.startsWith("data:image/");
  };

  const imageUrl =
    edit && image && !isBase64Image(image) ? DirectusImage(image) : image;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className={styles.preview_album}>
      {vote ? null : <span>Preview</span>}

      <div
        className={styles.preview}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {imageUrl ? <img src={imageUrl} alt="loop-album" /> : null}
        <div className={styles.club_details}>
          <div className={styles.details}>
            <div>
              <p>{collectionName ? collectionName : null}</p>
            </div>
          </div>
        </div>
      </div>

      <small>
        <b>Note:</b> Please ensure that the artwork is designed in a card layout
        format to maintain the aspect ratio. (796 x 1058px)
      </small>
    </div>
  );
}
