import axios from "axios";

export const addFileBenefit = async (
  fansCollectionId: string,
  cookie: string,
  fileName?: string,
  file?: any
) => {
  const formData = new FormData();
  formData.append("collection_id", fansCollectionId);
  formData.append("cookie", cookie);
  formData.append("type", "create");
  formData.append("name", fileName);
  formData.append("file", file[0].file);

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_MIDDLEWARE}benefit/files`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const editFileBenefit = async (
  fansCollectionId: string,
  cookie: string,
  videoName?: string,
  fileId?: string
) => {
  const formData = new FormData();
  formData.append("collection_id", fansCollectionId);
  formData.append("cookie", cookie);
  formData.append("type", "update");
  formData.append("name", videoName);
  formData.append("files_id", fileId);

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_MIDDLEWARE}benefit/files`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};
