import { createContext, useContext, useState } from "react";

interface LoginActions {
  showLoginModal: boolean;
  showSignup: boolean;
  artistSignup?: boolean;
  toggleArtistSignup?: (value:boolean) => void;
  toggleLoginModal: () => void;
  toggleSignupModal: (value:boolean) => void;
}

export const LoginProviderContext = createContext<LoginActions>({
  showLoginModal: false,
  showSignup: false,
  artistSignup: false,
  toggleArtistSignup: () => {},
  toggleLoginModal: () => {},
  toggleSignupModal: () => {},
});

export const LoginContextProvider = ({ children }) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [artistSignup, setArtistSignup] = useState(false);

  const toggleLoginModal = () => {
    setShowLoginModal(!showLoginModal);
  };

  const toggleSignupModal = (value:boolean) => {
    setShowSignup(value);
  };

  const toggleArtistSignup = (value:boolean) => {
    setArtistSignup(value);
  }

  return (
    <LoginProviderContext.Provider
      value={{
        showLoginModal,
        showSignup,
        artistSignup,
        toggleArtistSignup,
        toggleLoginModal,
        toggleSignupModal,
      }}
    >
      {children}
    </LoginProviderContext.Provider>
  );

};

export const useLogin = (): LoginActions => useContext(LoginProviderContext);
