import React, { useEffect, useRef, useState } from "react";
import styles from "./EditNft.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useHook } from "app/hooks/common";
import {
  DirectusAudio,
  DirectusImage,
  ProfileData,
  isCreator,
} from "app/core/common";
import * as api from "app/api/profile.api";
import {
  convertElementToSvgFile,
  createVoteNFT,
  editVoteNFT,
  setGenreAndDivision,
} from "app/helpers/CreateNft";
import Button from "app/components/button/button";
import Loader from "app/components/Loader";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { blue } from "@mui/material/colors";
import FadeLoader from "react-spinners/FadeLoader";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import axios from "axios";
import images from "./images.json";
import OnBoardNft from "app/components/onboard/onboard_nft_image/OnBoardNft";
import PreviewImage from "app/components/PreviewImage/PreviewImage";

export default function EditNft() {
  const location = useLocation();
  const [songFile, setSongFile] = useState(null);
  const [originalSongFile, setOriginalSongFile] = useState(null);
  const [nftImage, setNftImage] = useState(null);
  const [nftBackGround, setNftBackGround] = useState(null);
  const [genre, setGenre] = useState<string | null>(null);
  const [division, setDivision] = useState<string | null>(null);
  const refCover = useRef<HTMLInputElement | null>(null);
  const refSong = useRef<HTMLInputElement | null>(null);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [converting, setConverting] = useState<boolean>(false);
  const [genres, setGenres] = useState(null);
  const [divisions, setDivisions] = useState(null);
  const { userInfo, cookie, updateUserInfo } = useHook();
  const history = useHistory();
  const userId = userInfo?.id;
  const [profileData, setProfileData] = useState<ProfileData>(null);
  const [imageProfileFinal, setImageProfileFinal] = useState(null);
  const [imageProfile, setImageProfile] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const id = location.pathname.split("/")[2];
  const type = "vote";

  const imageBottomRigth =
    "https://loop-markets.directus.app/assets/ce36c33e-2a96-4543-9a95-ff791dc70a21";
  const imageBottomLeft =
    "https://loop-markets.directus.app/assets/9fd7b350-b91a-48ff-b493-659351368603";
  const imageTop =
    "https://loop-markets.directus.app/assets/27e236b8-0989-42a1-90b8-774f33839f54";

  const [infos, setInfos] = useState({
    username: "",
    display_name: "",
  });

  /* ====================== */
  /* Get Collection information to fill collection info */
  /* ======== Start ======= */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios({
          url: `${process.env.REACT_APP_MIDDLEWARE}fans/fans_launchpad/${id}?isId=true`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
            user_cookie: cookie,
          },
        });

        /* if (result?.data?.info?.collection_type === "support") {
          history.push(`/liveCollections/`);
        } */

        if (result?.data?.info?.launchpad_type[0]?.fan_collection?.song) {
          const song =
            result?.data?.info?.launchpad_type[0]?.fan_collection?.song;
          setSongFile(song);
          setOriginalSongFile(song);
        }
        if (
          result?.data?.info?.launchpad_type[0]?.fan_collection?.leaderboard
            ?.division?.id
        ) {
          setDivision(
            result?.data?.info?.launchpad_type[0]?.fan_collection?.leaderboard
              ?.division?.id
          );
        }
        if (
          result?.data?.info?.launchpad_type[0]?.fan_collection?.leaderboard
            ?.division?.id
        ) {
          setDivision(
            result?.data?.info?.launchpad_type[0]?.fan_collection?.leaderboard
              ?.division?.id
          );
        }
        if (
          result?.data?.info?.launchpad_type[0]?.fan_collection?.leaderboard
            ?.genre?.id
        ) {
          setGenre(
            result?.data?.info?.launchpad_type[0]?.fan_collection?.leaderboard
              ?.genre?.id
          );
        }

        if (result?.data?.info?.launchpad_type[0]?.fan_collection?.banner) {
          setNftBackGround(
            result?.data?.info?.launchpad_type[0]?.fan_collection?.banner?.id
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [userId]);
  /* ====================== */
  /* Get Collection information to fill collection info */
  /* ======== End ======= */

  /* ====================== */
  /* Get User information to fill nft info */
  /* ======== Start ======= */
  useEffect(() => {
    const fetchData = async () => {
      try {
        await api.GetProfile({ id: userId }).then((user) => {
          setProfileData(user[0]);

          // Set the initial values for the profile and cover pictures
          if (user[0]?.avatar?.id) {
            setImageProfileFinal(DirectusImage(user[0]?.avatar?.id));
            setImageProfile(DirectusImage(user[0]?.avatar?.id));
          }
          if (user[0]?.display_name) {
            setInfos((prevInfos) => ({
              ...prevInfos,
              display_name: user[0]?.display_name,
            }));
          }
          if (user[0]?.username) {
            setInfos((prevInfos) => ({
              ...prevInfos,
              username: user[0]?.username,
            }));
          }
        });
        return updateUserInfo();
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [userId]);
  /* ====================== */
  /*  Get User information to fill nft info */
  /* ======== End ======= */

  /* ====================== */
  /* Get division and genres of the NFT */
  /* ======== Start ======= */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [response, responseGeners] = await setGenreAndDivision();
        setDivisions(response?.data?.divisions);
        setGenres(responseGeners?.data?.genres);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  /* ====================== */
  /*  Get division and genres of the NFT */
  /* ======== End ======= */

  // check the selected music file for type and size
  const handleMusicFile = (e) => {
    let file = e.target.files[0];
    if (file.type !== "audio/mp3" && file.type !== "audio/mpeg") {
      alert(`The format of the file is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 25) {
      alert("The size of the file is to large");
      return;
    }
    setSongFile(file);
  };

  // store the selected value for genre and division
  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    if (name === "genre") {
      setGenre(value);
    } else {
      setDivision(value);
    }
  };

  /* ====================== */
  /*  handleCreateVoteNFT "controll the creaton of the vote NFT" */
  /* ======== Start ======= */
  useEffect(() => {
    if (genre && division && songFile) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [genre, division, cookie, songFile]);

  /* ====================== */
  /*  handleEditVoteNFT "controll the editiop of the vote NFT" */
  /* ======== Start ======= */
  const handleEditVoteNFT = async (status) => {
    try {
      setLoading((prev) => !prev);
      const fileToSend = songFile === originalSongFile ? null : songFile;
      const response = await editVoteNFT(
        genre,
        division,
        fileToSend,
        type,
        cookie,
        id,
        status
      );

      if (response?.status === 200) {
        // Continue to next step
        setGenre("");
        setDivision("");
        setNftImage(null);
        setSongFile(null);
        setNftBackGround(null);
        setLoading((prev) => !prev);

        history.push(`/liveCollections/`);
      }
      if (response?.status !== 200) {
        // Continue to next step

        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  /* ====================== */
  /*  handleCreateVoteNFT "controll the creaton of the vote NFT" */
  /* ======== End ======= */

  const audioSrc =
    songFile && songFile !== originalSongFile
      ? URL.createObjectURL(songFile)
      : DirectusAudio(songFile?.id);

  useEffect(() => {
    // Revoke the object URL when the component unmounts or the file changes
    return () => {
      if (audioSrc && songFile !== originalSongFile) {
        URL.revokeObjectURL(audioSrc);
      }
    };
  }, [songFile, originalSongFile, audioSrc]);

  return (
    <div className={styles.step_five_all}>
      <div className={styles.edit_container}>
        <div className={styles.step_five_category}>
          <h1>Edit Vote NFT</h1>
          <div className={styles.categories_select}>
            <div className={styles.select1}>
              <div className={styles.upload_music_button}>
                {/* <h1>Upload a song</h1> */}
                <span>
                  Which song would you like to enter into the awards?
                  <small> (Max: 25MB MP3)</small>
                </span>
                <div
                  className={styles.image_icon}
                  onClick={() => {
                    refSong.current.click();
                  }}
                >
                  <LibraryMusicIcon sx={{ color: blue[700] }} />
                  <input
                    type="file"
                    ref={refSong}
                    hidden
                    onChange={handleMusicFile}
                    accept="audio/mp3"
                  />
                  <span className={styles.input_song_title}>
                    {songFile ? "song uploaded" : "No file selected"}
                    <small> (Max: 25MB MP3)</small>
                  </span>
                </div>
                <div>
                  <audio
                    src={audioSrc}
                    controls
                    loop
                    autoPlay={false}
                    className={styles.audio_player}
                    controlsList="nodownload noplaybackrate"
                  />
                </div>
              </div>
            </div>
            <div className={styles.select2}>
              <>
                <label htmlFor="genre">
                  Which genre would you like to compete in?
                </label>
                <select
                  name="genre"
                  id="genre"
                  required
                  onChange={handleRegisterChange}
                  value={genre}
                  style={{ marginBottom: "10px" }}
                >
                  {genres &&
                    genres?.map((gen) => (
                      <option key={gen.id} value={gen.id}>
                        {gen.name}
                      </option>
                    ))}
                </select>
              </>

              <>
                <label htmlFor="division">Which division are you in?</label>
                <select
                  name="division"
                  id="division"
                  required
                  onChange={handleRegisterChange}
                  value={division}
                >
                  {divisions &&
                    divisions?.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.description
                          ? `${option.name} - (${option.description})`
                          : option.name}
                      </option>
                    ))}
                </select>
              </>
            </div>
          </div>
          <hr />
        </div>
        <div className={styles.step_five}>
          <PreviewImage edit image={nftBackGround} vote />
          <Button
            className="btn_md"
            disabled={!ready}
            onClick={() => handleEditVoteNFT("published")}
            loading={loading}
          >
            Save Now
          </Button>
        </div>
      </div>
    </div>
  );
}
